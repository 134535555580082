<template>
  <div>
    <div class="d-flex flex-column">
      <TopNav />
      <div class="sub_nav-container">

        <div class="left_nav-btn-container">
          <div class="left_nav-open-btn" :class="{'rotate_btn': !borderPosition }" @click="moveBorder()"></div>
        </div>
<!--  Top Menu -->
        <ul class="sub_nav-links">
          <router-link to="/dashboardLeads">
            <li :class="{'active_nav-tab': currentRouteName === 'DashboardLeads'}">
              <svg xmlns="http://www.w3.org/2000/svg" width="21.931" height="18.635" viewBox="0 0 21.931 18.635">
                <g id="Group_11086" data-name="Group 11086" transform="translate(-357 -113)">
                  <g id="Group_436" data-name="Group 436" transform="translate(-881.245 -1556.289)">
                    <path id="Path_280" data-name="Path 280" d="M1311.784,1777.38q-2.1,0-4.195,0c-.725,0-.911-.189-.912-.919,0-1.969-.006-3.938,0-5.907a3.186,3.186,0,0,1,3.372-3.388q1.734-.01,3.467,0a3.185,3.185,0,0,1,3.372,3.388c.007,1.969,0,3.938,0,5.907,0,.731-.187.918-.912.92Q1313.881,1777.383,1311.784,1777.38Z" transform="translate(-62.57 -89.491)" fill="#fff"/>
                    <path id="Path_281" data-name="Path 281" d="M1329.612,1673.107a3.8,3.8,0,1,1-3.751-3.818A3.785,3.785,0,0,1,1329.612,1673.107Z" transform="translate(-76.602 0)" fill="#fff"/>
                    <path id="Path_282" data-name="Path 282" d="M1238.255,1791.229a14.376,14.376,0,0,1,.066-2.109,3.117,3.117,0,0,1,2.976-2.543c.5-.019,1,0,1.5-.012.239-.006.306.074.23.3a4.71,4.71,0,0,0-.171,1.523c0,1.983,0,3.966,0,5.949,0,.25.16.577,0,.728-.18.166-.518.054-.786.053-.7,0-1.4.023-2.095-.036a1.835,1.835,0,0,1-1.708-1.86C1238.242,1792.609,1238.255,1792,1238.255,1791.229Z" transform="translate(0 -107.233)" fill="#fff"/>
                    <path id="Path_283" data-name="Path 283" d="M1443.157,1791.328c0,.571.005,1.141,0,1.712a1.868,1.868,0,0,1-1.85,2c-.853.052-1.711.012-2.567.023-.226,0-.225-.088-.22-.286.067-2.41.052-4.821.01-7.231a2.269,2.269,0,0,0-.122-.671c-.091-.273-.058-.391.264-.375a12.423,12.423,0,0,1,1.964.069,3.151,3.151,0,0,1,2.521,3.093C1443.163,1790.215,1443.157,1790.772,1443.157,1791.328Z" transform="translate(-182.983 -107.167)" fill="#fff"/>
                    <path id="Path_284" data-name="Path 284" d="M1258.014,1716.474a2.837,2.837,0,1,1,2.838-2.86A2.816,2.816,0,0,1,1258.014,1716.474Z" transform="translate(-15.482 -37.956)" fill="#fff"/>
                    <path id="Path_285" data-name="Path 285" d="M1416.918,1713.657a2.838,2.838,0,1,1-2.8-2.858A2.819,2.819,0,0,1,1416.918,1713.657Z" transform="translate(-158.185 -37.956)" fill="#fff"/>
                  </g>
                </g>
              </svg>
              Leads
<!--              <div v-if="leads > 0" class="link_point">{{leads}}</div>-->
            </li>
          </router-link>
          <router-link to="/inbox">
            <li :class="{'active_nav-tab': currentRouteName === 'Inbox'}">
              <svg xmlns="http://www.w3.org/2000/svg" width="20.258" height="16.181" viewBox="0 0 20.258 16.181">
                <g id="Group_11087" data-name="Group 11087" transform="translate(-521.666 -114)">
                  <g id="Group_437" data-name="Group 437" transform="translate(-1.525 -1654.685)">
                    <path id="Path_286" data-name="Path 286" d="M523.222,1772.07c-.014.174-.026.25-.026.326,0,3.13-.014,6.26,0,9.389a3.007,3.007,0,0,0,3.149,3.076q6.947.011,13.894,0a3.028,3.028,0,0,0,3.2-3.22q.009-4.552,0-9.1c0-.132-.021-.265-.037-.463-.222.128-.383.22-.543.313-2.754,1.609-5.515,3.208-8.258,4.835a2.311,2.311,0,0,1-2.578.005c-2.755-1.639-5.528-3.247-8.294-4.867C523.589,1772.275,523.44,1772.193,523.222,1772.07Zm.53-2.079a1.784,1.784,0,0,0,.166.128q4.576,2.685,9.161,5.355a.7.7,0,0,0,.579-.046q4.465-2.594,8.912-5.218c.1-.06.194-.138.3-.219a3.118,3.118,0,0,0-2.678-1.306c-4.584.011-9.168,0-13.752.006a4.589,4.589,0,0,0-.568.021A2.927,2.927,0,0,0,523.752,1769.991Z" fill="#fff"/>
                    <path id="Path_287" data-name="Path 287" d="M523.222,1804.375c.218.123.367.2.514.29,2.766,1.62,5.539,3.229,8.294,4.867a2.311,2.311,0,0,0,2.578-.005c2.743-1.627,5.5-3.226,8.258-4.835.16-.094.322-.186.543-.313.016.2.037.331.037.463q0,4.553,0,9.1a3.028,3.028,0,0,1-3.2,3.219q-6.947.008-13.894,0a3.007,3.007,0,0,1-3.149-3.076c-.019-3.13-.006-6.26,0-9.389C523.2,1804.625,523.208,1804.549,523.222,1804.375Z" transform="translate(0 -32.305)" fill="#fff"/>
                    <path id="Path_288" data-name="Path 288" d="M529.108,1769.991a2.927,2.927,0,0,1,2.124-1.28,4.591,4.591,0,0,1,.568-.021c4.584,0,9.168.006,13.752-.006a3.119,3.119,0,0,1,2.678,1.306c-.111.08-.2.159-.3.219q-4.452,2.615-8.912,5.218a.7.7,0,0,1-.579.046q-4.592-2.659-9.161-5.355A1.776,1.776,0,0,1,529.108,1769.991Z" transform="translate(-5.356)" fill="#fff"/>
                  </g>
                </g>
              </svg>
              Inbox
<!--              <div v-if="inbox > 0" class="link_point">{{inbox}}</div>-->
            </li>
          </router-link>
<!--          <router-link to="/adTemplates">-->
<!--            <li :class="{'active_nav-tab': currentRouteName === 'AdTemplates'}">-->
<!--              <svg xmlns="http://www.w3.org/2000/svg" width="20.802" height="18.774" viewBox="0 0 20.802 18.774">-->
<!--                <g id="Group_438" data-name="Group 438" transform="translate(-1706.989 -1374.475)">-->
<!--                  <path id="Path_289" data-name="Path 289" d="M1717.4,1389.022h-8.853c-1.147,0-1.558-.415-1.558-1.568q0-5.746,0-11.492c0-1.04.439-1.486,1.477-1.486q8.914,0,17.828,0c1.065,0,1.5.438,1.5,1.506q0,5.787,0,11.574c0,1.012-.447,1.465-1.457,1.466Q1721.867,1389.025,1717.4,1389.022Z" fill="#fff"/>-->
<!--                  <path id="Path_290" data-name="Path 290" d="M1754.675,1571.4h-6.087c-.122,0-.244.005-.365,0a.6.6,0,0,1-.629-.6.588.588,0,0,1,.624-.606c.73-.017,1.461-.007,2.191-.008,1.075,0,1.075,0,1.075-1.072,0-.716,0-.716.7-.716,1.758,0,3.517.009,5.275-.007.342,0,.5.065.459.441-.034.335.007.676-.011,1.013-.015.269.09.348.35.344.892-.012,1.785-.006,2.678,0a1.7,1.7,0,0,1,.442.039.6.6,0,0,1-.007,1.142,1.987,1.987,0,0,1-.483.038Q1757.779,1571.4,1754.675,1571.4Z" transform="translate(-37.306 -178.156)" fill="#fff"/>-->
<!--                </g>-->
<!--              </svg>-->
<!--              Ad Templates-->
<!--            </li>-->
<!--          </router-link>-->
          <router-link to="/funnels">
            <li :class="{'active_nav-tab': currentRouteName === 'Funnels'}">
              <svg xmlns="http://www.w3.org/2000/svg" width="20.046" height="18.706" viewBox="0 0 20.046 18.706">
                <path id="Path_291" data-name="Path 291" d="M1876.73-255.45q3.9,0,7.8,0a2.015,2.015,0,0,1,2.192,2.174q0,5.175,0,10.351a2.016,2.016,0,0,1-2.194,2.173c-2.462,0-4.925.009-7.387-.008a1.42,1.42,0,0,0-.976.348c-1.39,1.134-2.8,2.246-4.2,3.369-.271.217-.538.408-.9.224s-.381-.507-.378-.855c.007-.918-.006-1.837.008-2.754,0-.253-.061-.339-.324-.33-.542.02-1.085.011-1.627,0a2,2,0,0,1-2.06-2.054q-.01-5.28,0-10.559a2,2,0,0,1,2.114-2.083Q1872.766-255.455,1876.73-255.45Zm-.051,6.68h3.09c.751,0,1.5.008,2.254,0a.661.661,0,0,0,.692-.678.672.672,0,0,0-.68-.65c-.111-.007-.223,0-.334,0h-9.812c-.195,0-.391-.011-.584.007a.637.637,0,0,0-.578.47.622.622,0,0,0,.164.653.821.821,0,0,0,.654.206Q1874.111-248.774,1876.679-248.77Zm-2.642,1.338c-.849,0-1.7,0-2.547,0-.494,0-.791.255-.794.662s.3.669.786.67q2.547.005,5.094,0c.491,0,.792-.259.794-.662s-.3-.667-.787-.669C1875.735-247.435,1874.886-247.432,1874.037-247.432Z" transform="translate(-1866.683 255.451)" fill="#fff"/>
              </svg>
              Funnels
            </li>
          </router-link>
<!--          <router-link to="/marketing">-->
<!--            <li :class="{'active_nav-tab': currentRouteName === 'Marketing'}">-->
<!--              <svg xmlns="http://www.w3.org/2000/svg" width="21.005" height="21" viewBox="0 0 21.005 21">-->
<!--                <g id="Group_8220" data-name="Group 8220" transform="translate(-1979.946 -978.943)">-->
<!--                  <path id="Path_293" data-name="Path 293" d="M1994.891,979.966c-.419.683-1.394,1-1.293,2.057a8.033,8.033,0,0,0-6.672.192,7.853,7.853,0,0,0-3.665,3.621,8.048,8.048,0,0,0,11.456,10.435,8.122,8.122,0,0,0,3.176-9.96c1.009.088,1.347-.843,2.006-1.295a10.33,10.33,0,0,1-2.343,12.16,10.494,10.494,0,1,1-2.665-17.209Z" fill="#fff"/>-->
<!--                  <path id="Path_294" data-name="Path 294" d="M2035.992,1040.482a5.6,5.6,0,0,1,7.692-5.171c.3.123.412.2.112.47-.464.42-.9.872-1.331,1.327a.78.78,0,0,1-.686.26,3.145,3.145,0,1,0,2.949,2.945.776.776,0,0,1,.263-.685c.434-.41.849-.842,1.271-1.264.364-.363.364-.362.556.134a5.6,5.6,0,1,1-10.825,1.984Z" transform="translate(-51.142 -51.056)" fill="#fff"/>-->
<!--                  <path id="Path_295" data-name="Path 295" d="M2095.69,980.919c.773-.623,1.344-.682,1.806-.234s.424,1.021-.2,1.832c.487,0,.929,0,1.371,0,.2,0,.386.007.483.215s-.038.357-.174.493c-.742.742-1.487,1.482-2.225,2.228a.626.626,0,0,1-.543.216,1.067,1.067,0,0,0-.131,0,5.748,5.748,0,0,0-1.988.074c-.561.229-.953.871-1.417,1.335q-1.548,1.545-3.094,3.092a1.1,1.1,0,1,1-1.536-1.547c1.379-1.383,2.754-2.772,4.15-4.137a1.048,1.048,0,0,0,.352-.877c-.025-.524,0-1.05-.011-1.574a.766.766,0,0,1,.256-.615c.729-.714,1.446-1.441,2.167-2.162.145-.145.285-.314.524-.2.207.1.21.292.209.488C2095.688,979.98,2095.69,980.414,2095.69,980.919Z" transform="translate(-98.24 -0.07)" fill="#fff"/>-->
<!--                </g>-->
<!--              </svg>-->
<!--              Marketing-->
<!--            </li>-->
<!--          </router-link>-->
        </ul>

      </div>
<!--Left menu-->
      <div class="left_nav-border" :class="{'move_sidebar_border': borderPosition }">
        <div class="left_nav-container" :class="{'move_sidebar': borderPosition }">
          <div>
            <ul class="left_nav-links">
<!--              <router-link to="/mainDashboard">-->
<!--                <li :class="{'active_left-nav-tab': currentRouteName === 'MainDashboard'}">Dashboard-->
<!--                  <span>-->
<!--                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">-->
<!--                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>-->
<!--                  </svg>-->
<!--              </span>-->
<!--                </li>-->
<!--              </router-link>-->

              <router-link to="/leadStatuses">
                <li :class="{'active_left-nav-tab': currentRouteName === 'LeadStatuses'}">Lead Statuses
                  <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </span>
                </li>
              </router-link>

              <router-link to="/payment">
                <li :class="{'active_left-nav-tab': currentRouteName === 'Payment'}">Subscription Payment
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                  </span>
                </li>
              </router-link>
<!--              <router-link to="/paymentHistory">-->
<!--                <li :class="{'active_left-nav-tab': currentRouteName === 'PaymentHistory'}">Payment History-->
<!--                  <span>-->
<!--                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">-->
<!--                      <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>-->
<!--                    </svg>-->
<!--                  </span>-->
<!--                </li>-->
<!--              </router-link>-->
<!--              <router-link to="/crmConnect">-->
<!--                <li :class="{'active_left-nav-tab': currentRouteName === 'CrmConnect'}">Connect Your CRM-->
<!--                  <span>-->
<!--                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">-->
<!--                      <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>-->
<!--                    </svg>-->
<!--                  </span>-->
<!--                </li>-->
<!--              </router-link>-->
<!--              <router-link to="/emailTextTemplates">-->
<!--                <li :class="{'active_left-nav-tab': currentRouteName === 'EmailTextTemplates'}">Email & Text Templates-->
<!--                  <span>-->
<!--                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">-->
<!--                      <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>-->
<!--                    </svg>-->
<!--                  </span>-->
<!--                </li>-->
<!--              </router-link>-->
<!--              <router-link to="/budgetPayment">-->
<!--                <li :class="{'active_left-nav-tab': currentRouteName === 'BudgetPayment'}">Ad Budget Payment-->
<!--                  <span>-->
<!--                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">-->
<!--                      <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>-->
<!--                    </svg>-->
<!--                  </span>-->
<!--                </li>-->
<!--              </router-link>-->
<!--              <router-link to="/buyerLinks">-->
<!--                <li :class="{'active_left-nav-tab': currentRouteName === 'BuyerLinks'}">Buyer Links-->
<!--                  <span>-->
<!--                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">-->
<!--                      <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>-->
<!--                    </svg>-->
<!--                  </span>-->
<!--                </li>-->
<!--              </router-link>-->
<!--              <router-link to="/sellerLinks">-->
<!--                <li :class="{'active_left-nav-tab': currentRouteName === 'SellerLinks'}">Seller Links-->
<!--                  <span>-->
<!--                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">-->
<!--                      <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>-->
<!--                    </svg>-->
<!--                  </span>-->
<!--                </li>-->
<!--              </router-link>-->
            </ul>
          </div>
        </div>

        <slot></slot>
      </div>

    </div>


  </div>
</template>

<script>
  import TopNav from "../TopNav";
  import {mapActions} from "vuex";
export default {
  name: "Dashboard",
  data() {
    return {
      borderPosition: true,
      activeTab: 'leads',
      leads: 0,
      inbox: 11
    }
  },
  components: {
    TopNav
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  },
  created() {
    this.getAllLeads()
    .then(res => {
      if(res.status === 200) {
        this.leads = res.data.success.length
      }
    })
  },
  methods: {
    ...mapActions(['getAllLeads']),
    moveBorder() {
      this.borderPosition = !this.borderPosition
      alert.$emit("clickOn", this.borderPosition);
    }
  }
}
</script>

<style scoped lang="scss">

  .move_sidebar {
    transform: translateX(-315px);
    opacity: 0;
    visibility: hidden;
    transition: .4s;
  }
  .move_sidebar_border {
    transition: .4s;
    padding-left: 0 !important;
  }
.sub_nav {
  &-container {
    width: 100%;
    height: 60px;
    background: $light_blue;
    display: flex;
    align-items: center;
    position: sticky;
    top: 90px;
    z-index: 10;
    @media screen and (max-width: 768px) {
      top: 75px;
    }
    @media screen and (max-width: 425px) {
        height: 55px;
        overflow-x: auto;
    }
  }
  &-links {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    height: 100%;
    a {
      height: 100%;
    }
    & .active_nav-tab {
      background: #2E80B7;
    }
    & li {
      list-style-type: none;
      height: 100%;
      padding: 0 30px;
      color: $white;
      font-size: 18px;
      border-right: 1px solid #ffffff42;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover {
        background: #2E80B7;
      }
      & .link_point {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        box-shadow: 0 3px 6px #00000029;
        background: #22C558;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        margin-left: 10px;
      }
      & svg {
        margin-right: 10px;
      }
    }
  }
}
  .left_nav {
    &-border {
      display: flex;
      padding-left: 315px;
      transition: .4s;
      @media screen and (max-width: 1440px) {
        padding-left: 260px;
      }
      @media screen and (max-width: 768px) {
        padding-left: 210px;
      }
      @media screen and (max-width: 425px) {
        padding-left: 10px;
      }
    }
    &-container {
      width: 315px;
      height: 100%;
      background: $main_blue;
      position: fixed;
      left: 0;
      visibility: visible;
      opacity: 1;
      transition: .4s;
      @media screen and (max-width: 1440px) {
        width: 260px;
      }
      @media screen and (max-width: 768px) {
        width: 210px;
        z-index: 2;
      }
    }
    &-links {
      padding-top: 50px;
      @media screen and (max-width: 768px) {
        padding-top: 40px;
      }
      & .active_left-nav-tab {
        background: #04355B;
      }
      & li {
        width: 100%;
        list-style-type: none;
        color: #7091A7;
        padding: 10px 20px 12px 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        @media screen and (max-width: 1440px) {
          padding: 12px 20px;
        }
        @media screen and (max-width: 768px) {
            padding: 10px 15px;
          font-size: 14px;
        }
        &:hover {
          background: #04355B;
        }
      }
    }
    &-btn-container {
      width: 315px;
      height: 100%;
      padding: 0 30px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-right: 1px solid rgba(255, 255, 255, 0.26);
      @media screen and (max-width: 1440px) {
        width: 260px;
      }
      @media screen and (max-width: 768px) {
        width: 210px;
      }
      @media screen and (max-width: 425px) {
        width: 100px;
      }
      & .rotate_btn {
        transform: rotate(180deg);
        transition: .4s;
      }
    }
    &-open-btn {
      width: 38px;
      height: 34px;
      background: url("~@/assets/images/right_arrow_btn.svg");
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      transition: .4s;
    }
  }
</style>
