import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        baseURL: process.env.VUE_APP_BASE_URL,
        access_token: localStorage.getItem('access_token') || '',
        userData: JSON.parse(localStorage.getItem('userData')) || '',
        interest_user_id: null,
        register_user: {
            plan_id: null,
            subscription_type: null,
            city: null,
            count_of_people: null,
            email: null,
            firstName: null,
            lastName: null,
            phone: null,
            zip_code: null
        }
    },
    mutations: {
        USER_DATA(state, data) {
            state.userData = data
            localStorage.setItem('userData', JSON.stringify(data))
        },
        clearUserData(state) {
            state.access_token = '';
            state.userData = ''
            localStorage.removeItem('userData')
            localStorage.removeItem('access_token')
            // sessionStorage.clear();
        },
        INTEREST_USER(state, data) {
            state.interest_user_id = data
        },
        REGISTER_USER(state, data) {
            state.register_user.city = data.city,
            state.register_user.count_of_people = data.count_of_people,
            state.register_user.email = data.email,
            state.register_user.firstName = data.firstName,
            state.register_user.lastName = data.lastName,
            state.register_user.phone = data.phone,
            state.register_user.zip_code = data.zip_code
        },
        CREATE_PLAN_DATA(state, data) {
            state.register_user.plan_id = data.plan_id
            state.register_user.subscription_type = data.subscription_type
        }

    },
    actions: {
        registerUser({state, commit}, data) {
            return axios.post(`${state.baseURL}register`, {
                first_name: data.firstName,
                last_name: data.lastName,
                state: data.state,
                city: data.city,
                zip_code: data.zipCode,
                email: data.email,
                password: data.password,
                c_password: data.c_password,
                agree: data.agree,
                interested_user_id: state.interest_user_id
            })
                .then((response) => {
                    if(response) {
                        commit('USER_DATA', response.data.success.user)
                        commit('INTEREST_USER', null)
                        let access_token = response.data.success.token
                        localStorage.setItem('access_token', access_token)
                        axios.defaults.headers.Authorization = `Bearer ${access_token}`;
                        state.access_token = access_token
                        return response
                    }
                })
                .catch((error) => {
                    return error
                })
        },
        loginUser({state, commit}, data) {
            return axios.post(`${state.baseURL}login`, {
                email: data.email,
                password: data.password
            })
                .then((response) => {
                    if(response) {
                        commit('USER_DATA', response.data.success.user)
                        let access_token = response.data.success.token
                        localStorage.setItem('access_token', access_token)
                        axios.defaults.headers.Authorization = `Bearer ${access_token}`;
                        state.access_token = access_token
                        return response
                    }
                })
                .catch((error) => {
                    return error
                })
        },
        getUserData({state, commit}) {
          return axios.get(`${state.baseURL}get-user`)
              .then((response) => {
                  commit('USER_DATA', response.data.success)
                  return response
              })
              .catch((error) => {
                  return error
              })
        },
        sendForgot({state}, data) {
            return axios.post(`${state.baseURL}password/email`, {
                email: data
            })
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        resetPassword({state}, data) {
            return axios.post(`${state.baseURL}password/reset`, {
                password: data.newPassword,
                password_confirmation: data.confirmPassword,
                token: data.token,
                email: data.email
            })
                .then(response => {return response})
                .catch(error => {return error})
        },
        createNewLead({state}, data) {
            return axios.post(`${state.baseURL}lead`, {
                name: data.name,
                phone: data.phone,
                email: data.email,
                address: data.street_address,
                lead_type: data.lead_type,
                source: data.source,
                selling_in: data.selling_in,
                funnels_id: data.funnel_id,
            })
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        getAllLeads({state}) {
            return axios.get(`${state.baseURL}lead`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        createStatus({state}, data) {
          return axios.post(`${state.baseURL}status`, {
              name: data.name,
              color: data.color,
              lead_type: data.lead_type
          })
              .then(response => {
                  return response
              })
              .catch(error => {
                  return error
              })
        },
        getAllStatuses({state}) {
            return axios.get(`${state.baseURL}status`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        removeStatus({state}, data) {
            return axios.delete(`${state.baseURL}status/${data}`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        status_edit({state}, data) {
            return axios.put(`${state.baseURL}status/${data.id}`, {
                name: data.name,
                color: data.color,
                lead_type: data.lead_type
            })
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        createFunnel({state}, data) {
            return axios.post(`${state.baseURL}funnel`, {
                funnel_name: data.funnel_name,
                funnel_text: data.funnel_text,
                frequency: data.frequency,
                frequency_type: data.frequency_value,
                leads_id: data.leads
            })
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        getAllFunnels({state}) {
            return axios.get(`${state.baseURL}funnel`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        deleteFunnel({state}, data) {
            return axios.delete(`${state.baseURL}funnel/${data}`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        editFunnel({state}, data) {
            console.log(data)
            return axios.put(`${state.baseURL}funnel/${data.funnel_edit_id}`, {
                funnel_name: data.funnel_name,
                funnel_text: data.funnel_text,
                frequency: data.frequency,
                frequency_type: data.frequency_value,
                lead_id: data.leads_id,
            })
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        getLeadData({state}, data) {
            return axios.get(`${state.baseURL}lead/${data}`)
                .then(response => { return response})
                .catch(error => { return error})
        },
        leadEdit({state}, data) {
            return axios.put(`${state.baseURL}lead/${data.lead_id}`, {
                name: data.name,
                phone: data.phone,
                email: data.email,
                lead_type: data.lead_type,
                address: data.address,
                city: data.city,
                state: data.state,
                zip: data.zip,
                selling_in: data.selling_in,
                appraisal: data.appraisal,
                beds: data.beds,
                bath: data.bath,
                buying_in: data.buying_in,
                price_range_min: data.price_range_min,
                price_range_max: data.price_range_max,
                neighborhoods: data.neighborhoods,
                pre_qualify: data.pre_qualify,
                lender: data.lender,
                lender_email: data.lender_email,
                lender_phone: data.lender_phone,
                credit: data.credit,
                source: data.source,
                funnels_id: data.funnels_id,
                funnel_delete_id: data.funnel_delete_id
            })
                .then(response => { return response})
                .catch(error => { return error})

        },
        leadRemove({state}, data) {
            return axios.delete(`${state.baseURL}lead/${data}`)
                .then(response => { return response})
                .catch(error => { return error})
        },
        noteAdd({state}, data) {
            return axios.post(`${state.baseURL}lead-note`, {
                lead_id: data.lead_id,
                description: data.description
            })
                .then(response => { return response})
                .catch(error => { return error})

        },
        readNotes({state}, data) {
            return axios.get(`${state.baseURL}lead-note/${data}`)
                .then(response => { return response})
                .catch(error => { return error})
        },
        removeNote({state}, data) {
            return axios.delete(`${state.baseURL}lead-note/${data}`)
                .then(response => { return response})
                .catch(error => { return error})
        },
        noteEdit({state}, data) {
            return axios.put(`${state.baseURL}lead-note/${data.id}`, {
                description: data.description
            })
                .then(response => { return response})
                .catch(error => { return error})
        },
        editLeadStatus({state}, data) {
            return axios.put(`${state.baseURL}lead-status/${data.lead_id}`, {
                status_id: data.status_id
            })
                .then(response => { return response})
                .catch(error => { return error})
        },
        sendEmail({state}, data) {
            let sendMessages = []
            data.forEach(item => {
                sendMessages.push(item)
            })
            return axios.post(`${state.baseURL}send-gmail`, {
                messages: sendMessages
            })
                .then(response => { return response})
                .catch(error => { return error})
        },
        getUrl({state}) {
            return axios.get(`${state.baseURL}google/redirect`)
                .then(response => {
                    return response
                })
                .catch(error => { return error})

        },
        getUrlSuccess({state}, data) {
            return axios.get(`${state.baseURL}google/redirect?code=${data}`)
                .then(response => { return response})
                .catch(error => { return error})

        },
        getChats({state}) {
            return axios.get(`${state.baseURL}get-chats`)
                .then(response => { return response})
                .catch(error => { return error})
        },
        getMessagesById({state}, data) {
            return axios.get(`${state.baseURL}get-messages/${data}`)
                .then(response => { return response})
                .catch(error => { return error})
        },
        getPlans({state}) {
            return axios.get(`${state.baseURL}plan`)
                .then(response => { return response})
                .catch(error => { return error})
        },
        getCreditCards({state}) {
          return axios.get(`${state.baseURL}card`)
              .then(response => { return response})
              .catch(error => { return error})
        },
        addNewCreditCard({state}, data) {
            let card = {
                name: data.cardName,
                number: data.cardNumber,
                exp_month: data.cardMonth,
                exp_year: data.cardYear,
                cvc: data.cardCvv
            }
            return axios.post(`${state.baseURL}card`, {
                card: card

            })
                .then(response => { return response})
                .catch(error => { return error})
        },
        removeCreditCard({state}, data) {
            return axios.delete(`${state.baseURL}card/${data}`)
                .then(response => { return response})
                .catch(error => { return error})
        },
        changeActiveCard({state}, data) {
            return axios.put(`${state.baseURL}card/${data}`)
                .then(response => { return response})
                .catch(error => { return error})
        },
        userSubscribe({state}, data) {
            return axios.post(`${state.baseURL}subscription`, {
                plan_id: data.plan_id,
                subscription_type: data.subscription_type
            })
                .then(response => { return response})
                .catch(error => { return error})
        },
        interestedUser({state}, data) {
            return axios.post(`${state.baseURL}interested`, {
                name: data.firstName + ' ' + data.lastName,
                email: data.email,
                phone: data.phone,
                city: data.city,
                zip_code: data.zip_code,
                count_of_people: data.count_of_people

            })
                .then(response => { return response})
                .catch(error => { return error})
        },
        // validateRegister({state}, data) {
        //     return axios.post(`${state.baseURL}register-valid`, {
        //         first_name
        //     })
        //         .then(response => { return response})
        //         .catch(error => { return error})
        // }

    },
    getters: {
        accessToken: state => state.access_token,
        userData: state => state.userData

    },
    modules: {}
})
