<template>
    <div class="w-100">
        <div class="navigation_container">
            <div class="navigation_content">
                <router-link to="/">
                    <div class="navigation_logo">
                    </div>
                </router-link>

                <div class="navigation_links">
                    <ul>

<!--                        <router-link to="/explore">-->
<!--                            <li>EXPLORE</li>-->
<!--                        </router-link>-->
<!--                        <li>SUCCESS STORIES</li>-->
                        <router-link to="/pricing">
                            <li>PRICING</li>
                        </router-link>

                        <router-link to="/login">
                            <li>LOGIN</li>
                        </router-link>
                        <router-link to="/register">
                            <li>SIGNUP</li>
                        </router-link>
                    </ul>
                    <a href="tel:+1-512-637-9251">
                        <b-button class="phone_btn">
                            <a href=""></a>
                            <b-icon icon="telephone-fill"></b-icon>
                            1-512-637-9251
                        </b-button>
                    </a>
                    <div>
                        <div class="burger-menu" :class="{'active': active}" @click="onBurgerClicked()">
                            <div class="burger-container">
                                <div class="burger-inner"></div>
                                <div class="menu_content" :class="{'show':active}">
                                    <div class="links" >
<!--                                        <div class="link_item">EXPLORE</div>-->
<!--                                        <div class="link_item">SUCCESS STORIES</div>-->
                                        <router-link to="/pricing">
                                            <div class="link_item">PRICING</div>
                                        </router-link>
                                        <router-link to="/login">
                                            <div class="link_item">LOGIN</div>
                                        </router-link>
                                        <router-link to="/register">
                                            <div class="link_item">SIGNUP</div>
                                        </router-link>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "NavBar",
        data() {
            return {
                active: false

            }
        },
        methods: {
            onBurgerClicked() {
                this.active = !this.active;
            }
        }
    }
</script>

<style scoped lang="scss">
    .navigation {
        &_container {
            /*max-width: 1640px;*/
            width: 100%;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            position: fixed;
            top: 0;
            z-index: 3;
            background: $white;
            @media screen and (max-width: 1700px) {
                padding: 0 60px;
            }
            @media screen and (max-width: 1024px) {
                padding: 0 30px;
            }
            @media screen and (max-width: 320px) {
                padding: 0 20px;
            }
        }

        &_content {
            max-width: 1640px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media screen and (max-width: 1024px) {
                max-width: 880px;
            }
        }

        &_logo {
            width: 220px;
            height: 30px;
            background: url("~@/assets/images/Logo.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            @media screen and (max-width: 1024px) {
                width: 200px;
            }
            @media screen and (max-width: 425px) {
                width: 150px;
            }
            @media screen and (max-width: 320px) {
                width: 120px;
            }
        }

        &_links {
            display: flex;
            align-items: center;

            & ul {
                list-style-type: none;
                display: flex;
                margin-bottom: 0;
                align-items: center;
                @media screen and (max-width: 768px) {
                    display: none;
                }

                & li {
                    padding: 0 20px;
                    cursor: pointer;
                    color: $grey;
                    @media screen and (max-width: 1200px) {
                        padding: 0 12px;
                    }
                    @media screen and (max-width: 1024px) {
                        font-size: 14px;
                        padding: 0 10px;
                    }

                    &:hover {
                        color: $light_blue;
                    }
                }
            }

            & .phone_btn {
                background: $light_blue;
                border: none;
                display: flex;
                align-items: center;
                margin-left: 20px;
                border-radius: 5px;
                @media screen and (max-width: 1024px) {
                    margin-left: 10px;
                    font-size: 14px;
                    & svg {
                        margin-right: 10px !important;
                    }
                }
                @media screen and (max-width: 320px) {
                    font-size: 12px;
                    & svg {
                        margin-right: 8px !important;
                    }
                }

                &:focus {
                    border: none;
                    box-shadow: none;
                }

                & svg {
                    margin-right: 20px;
                    width: 12px;
                    height: 12px;
                }
            }
        }
    }

    $burgerWidth: 24px;
    $burgerBarHeight: 1px;
    $burgerBarColor: #409edc;

    .burger-menu {
        width: $burgerWidth;
        height: $burgerWidth;
        cursor: pointer;
        margin: 0 30px;
        display: none;
        @media screen and (max-width: 768px) {
            display: block;
            margin: 0 0 0 30px;
        }
        @media screen and (max-width: 425px) {
            margin: 0 0 0 20px;
        }
        @media screen and (max-width: 320px) {
            margin: 0 0 0 15px;
        }

        .burger-container {
            position: relative;
            .menu_content {
                position: absolute;
                width: 200px;
                background: transparent;
                /*border: 1px solid #409edc;*/
                /*padding: 20px 0;*/
                top: 40px;
                right: 0;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                transform: translateX(50px);
                visibility: hidden;
                opacity: 0;
                transition: .4s ease-in-out;
                & .user_logo {
                    width: 100%;
                    height: 50px;
                    & .user_img {
                        & path {
                            fill: #000;
                        }
                    }
                    & span {
                        font-family: 'Chakra Petch', sans-serif;
                        margin-left: 15px;
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 18px;
                    }
                    & img {
                        width: 35px;
                        height: 35px;
                        margin: 0 15px;
                        border-radius: 50%;
                    }
                }
                & .mobile_logOut {
                    width: 100%;
                    text-align: center;
                    font-family: 'Chakra Petch', sans-serif;
                    color: #707070;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 16px;
                }
                & .links {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: column;
                    background: transparent;
                    box-shadow: -8px 25px 43px -18px rgba(0,0,0,0.28);
                }
                & .link_item {
                    width: 200px;
                    padding: 10px 30px;
                    font-weight: 500;
                    cursor: pointer;
                    display: flex;
                    justify-content: flex-end;
                    /*background: rgb(255,255,255);*/
                    /*background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(64,158,220,1) 100%);*/
                    background: #fff;
                    /*background: linear-gradient(90deg, rgba(255, 255, 255, 0.95) 0%, rgb(255, 255, 255) 100%);*/
                    /*box-shadow: 2px 2px 3px 10px #888;*/
                }
            }
            .show {
                transform: translateX(0px);
                visibility: visible;
                opacity: 1;
            }

            .burger-inner {
                position: absolute;
                width: $burgerWidth - 2 * $burgerBarHeight;
                left: 0;
                top: 11px;
                border: $burgerBarHeight solid $burgerBarColor;
                transition: border-color .2s ease-in .2s;

                &::before {
                    position: absolute;
                    content: '';
                    top: -6px;
                    left: -$burgerBarHeight;
                    width: $burgerWidth - 2 * $burgerBarHeight;
                    border: $burgerBarHeight solid $burgerBarColor;
                    transition: top .2s ease-in .3s, transform ease-in .2s;
                }

                &::after {
                    position: absolute;
                    content: '';
                    top: 4px;
                    left: -$burgerBarHeight;
                    width: $burgerWidth - 2 * $burgerBarHeight;
                    border: $burgerBarHeight solid $burgerBarColor;
                    transition: top .2s ease-in .3s, transform ease-in .2s;
                }
            }
        }

        &.active .burger-container .burger-inner {
            border-color: transparent;
            transition: border-color ease-in .1s;
        }

        &.active .burger-container .burger-inner::before {
            top: -$burgerBarHeight;
            transform: rotate(-45deg);
            transition: top ease-out .2s, transform .2s ease-out .3s;
        }

        &.active .burger-container .burger-inner::after {
            top: -$burgerBarHeight;
            transform: rotate(45deg);
            transition: top ease-out .2s, transform .2s ease-out .3s;
        }
    }

</style>
