<template>
    <div class="main_container">
        <NavBar/>
        <div class="main_home">
            <section>
                <div class="main_title_content">
                    <div class="main_title-part">
                        <div class="main_title">
                            Purchase exclusive and high quality leads now.
                        </div>
                        <div class="main_description">
                            Getting leads is hard and time-consuming. We make it faster, easier and more consistent so
                            you can focus on what you do best: close more deals.
                        </div>
                        <div class="main_buttons-part">
                            <b-button class="start_btn" @click="startGettingLeads()">Start Getting Leads</b-button>
                            <div class="main_play-btn" @click="playVideo()" id="video-btn">
                                <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56">
                                    <g id="Group_11034" data-name="Group 11034" transform="translate(-419 -443)">
                                        <g id="Ellipse_7" data-name="Ellipse 7" transform="translate(419 443)"
                                           fill="#fff" stroke="#409edc" stroke-width="1">
                                            <circle cx="28" cy="28" r="28" stroke="none"/>
                                            <circle cx="28" cy="28" r="27.5" fill="none"/>
                                        </g>
                                        <path id="Polygon_1" data-name="Polygon 1"
                                              d="M10.285,2.858a2,2,0,0,1,3.43,0l8.468,14.113A2,2,0,0,1,20.468,20H3.532a2,2,0,0,1-1.715-3.029Z"
                                              transform="translate(460 459) rotate(90)" fill="#409edc"/>
                                    </g>
                                </svg>
                            </div>
                            <div>Watch Video</div>
                        </div>
                    </div>
                    <div class="main_cards">
                        <div class="cards_item"></div>
                    </div>
                    <div class="main_images"></div>
                </div>
            </section>
            <!--     -->
<!--            <div class="main_statistic-part">-->
<!--                <div class="statistic_item even">-->
<!--                    <div class="item_points">350%+</div>-->
<!--                    <div class="item_text">Return On Investment</div>-->
<!--                    <div class="item_line"></div>-->
<!--                </div>-->
<!--                <div class="statistic_item odd">-->
<!--                    <div class="item_points">US and Canada</div>-->
<!--                    <div class="item_text">Serving Agents in the US and Canada</div>-->
<!--                    <div class="item_line"></div>-->
<!--                </div>-->
<!--                <div class="statistic_item even">-->
<!--                    <div class="item_points">Buyer and Seller</div>-->
<!--                    <div class="item_text">Leads</div>-->
<!--                    <div class="item_line"></div>-->
<!--                </div>-->
<!--                <div class="statistic_item odd">-->
<!--                    <div class="item_points">Software</div>-->
<!--                    <div class="item_text">For Agents</div>-->
<!--                    <div class="item_line"></div>-->
<!--                </div>-->
<!--            </div>-->
            <!--     -->
            <div class="testimonials_subtitle">
                <div class="subtitle">For many real estate agents, lead generation can be stressful. It’s because:</div>
                <div class="subtitle_data">
                    <svg data-v-2a42b908="" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#409EDC"
                         viewBox="0 0 16 16" class="bi bi-check-lg">
                        <path data-v-2a42b908=""
                              d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
                    </svg>
                    Agents lack the tools to do it.
                </div>
                <div class="subtitle_data">
                    <svg data-v-2a42b908="" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#409EDC"
                         viewBox="0 0 16 16" class="bi bi-check-lg">
                        <path data-v-2a42b908=""
                              d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
                    </svg>
                    Agents don’t know where to look for leads.
                </div>
                <div class="subtitle_data">
                    <svg data-v-2a42b908="" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#409EDC"
                         viewBox="0 0 16 16" class="bi bi-check-lg">
                        <path data-v-2a42b908=""
                              d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
                    </svg>
                    Agents simply don’t have enough time.
                </div>
                <div class="subtitle_sub">Rezi Leads solves all these problems</div>
            </div>
            <!--            -->
            <div class="main_cards-part">
                <div class="cards_item">
                    <div class="cards_logo">
                        <svg id="Group_48" data-name="Group 48" xmlns="http://www.w3.org/2000/svg" width="61.491"
                             height="59.666" viewBox="0 0 61.491 52.13">
                            <path id="Path_51" data-name="Path 51"
                                  d="M1304.665-951.066q-5.878,0-11.757,0c-2.05,0-2.574-.522-2.576-2.555-.006-5.519-.016-11.037,0-16.556.019-5.8,3.74-9.5,9.55-9.518q4.8-.018,9.6,0c5.731.03,9.45,3.741,9.472,9.475.021,5.518.008,11.037,0,16.555,0,2.1-.516,2.6-2.654,2.6Q1310.483-951.06,1304.665-951.066Z"
                                  transform="translate(-1273.891 1003.194)" fill="#00457b"/>
                            <path id="Path_52" data-name="Path 52"
                                  d="M1326.945-1066.872a10.606,10.606,0,0,1-10.838,10.586,10.61,10.61,0,0,1-10.436-10.866,10.658,10.658,0,0,1,10.776-10.431A10.655,10.655,0,0,1,1326.945-1066.872Z"
                                  transform="translate(-1285.553 1077.586)" fill="#00457b"/>
                            <path id="Path_53" data-name="Path 53"
                                  d="M1235.19-960.231c-.855,8.01-.228,15.913-.413,23.768-2.883,0-5.884.2-8.843-.065-2.274-.2-3.976-2.477-4.025-4.873-.072-3.517-.11-7.04.008-10.554a8.721,8.721,0,0,1,8.284-8.264C1231.871-960.293,1233.547-960.231,1235.19-960.231Z"
                                  transform="translate(-1221.842 988.41)" fill="#00457b"/>
                            <path id="Path_54" data-name="Path 54"
                                  d="M1422.54-960.686a45.442,45.442,0,0,1,6.548.414,8.547,8.547,0,0,1,6.749,8.385q.068,4.917,0,9.836a5.115,5.115,0,0,1-4.911,5.339c-2.66.167-5.34.035-7.972.035C1422.772-944.623,1423.374-952.553,1422.54-960.686Z"
                                  transform="translate(-1374.38 988.738)" fill="#00457b"/>
                            <path id="Path_55" data-name="Path 55"
                                  d="M1246.755-1036.077a7.925,7.925,0,0,1,7.976,7.872,7.934,7.934,0,0,1-7.937,8.032,7.9,7.9,0,0,1-7.968-7.988A7.919,7.919,0,0,1,1246.755-1036.077Z"
                                  transform="translate(-1234.75 1046.038)" fill="#00457b"/>
                            <path id="Path_56" data-name="Path 56"
                                  d="M1410.8-1028.166a7.894,7.894,0,0,1-7.963,7.993,7.936,7.936,0,0,1-7.943-7.907,7.926,7.926,0,0,1,7.971-8A7.919,7.919,0,0,1,1410.8-1028.166Z"
                                  transform="translate(-1353.365 1046.038)" fill="#00457b"/>
                        </svg>
                    </div>
                    <h4 class="cards_title">Get more leads</h4>
                    <div class="cards_description">Our technology helps real estate agents build a strong, recurring
                        source of buyer and seller leads in a fraction of the time..
                    </div>
                    <div class="cards_footer">
                        <div class="arrow_item">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                 width="74" height="74" viewBox="0 0 74 74">
                                <defs>
                                    <filter id="Ellipse_11" x="0" y="0" width="74" height="74"
                                            filterUnits="userSpaceOnUse">
                                        <feOffset dy="3" input="SourceAlpha"/>
                                        <feGaussianBlur stdDeviation="3" result="blur"/>
                                        <feFlood flood-opacity="0.161"/>
                                        <feComposite operator="in" in2="blur"/>
                                        <feComposite in="SourceGraphic"/>
                                    </filter>
                                </defs>
                                <g id="Group_11040" data-name="Group 11040" transform="translate(-586 -1657)">
                                    <g transform="matrix(1, 0, 0, 1, 586, 1657)" filter="url(#Ellipse_11)">
                                        <circle id="Ellipse_11-2" data-name="Ellipse 11" cx="28" cy="28" r="28"
                                                transform="translate(9 6)" fill="#00457b"/>
                                    </g>
                                    <g id="arrow-right_5_" data-name="arrow-right (5)" transform="translate(615 1679)">
                                        <line id="Line_4" data-name="Line 4" x2="22" transform="translate(-3 12)"
                                              fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"
                                              stroke-width="2"/>
                                        <path id="Path_65" data-name="Path 65" d="M12,5l7,7-7,7" fill="none"
                                              stroke="#fff" stroke-linecap="round" stroke-linejoin="round"
                                              stroke-width="2"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="cards_item">
                    <div class="cards_logo">
                        <svg id="Group_49" data-name="Group 49" xmlns="http://www.w3.org/2000/svg" width="55.607"
                             height="59.666" viewBox="0 0 55.607 59.666">
                            <path id="Path_57" data-name="Path 57"
                                  d="M1312.651-884.188V-923.9h46.213v39.714Zm2.369-26.55v24.181h41.531v-24.181Z"
                                  transform="translate(-1303.257 943.854)" fill="#00457b"/>
                            <path id="Path_58" data-name="Path 58"
                                  d="M1318.611-1009.4v17.539h-2.283v-4.307H1274.7v24.221h4.429v2.293H1272.4V-1009.4Z"
                                  transform="translate(-1272.4 1009.396)" fill="#00457b"/>
                        </svg>
                    </div>
                    <h4 class="cards_title">Convert more leads</h4>
                    <div class="cards_description">Using our AI-based CRM system, convert more buyer and seller leads
                        into deals.
                    </div>
                    <div class="cards_footer">
                        <div class="arrow_item">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                 width="74" height="74" viewBox="0 0 74 74">
                                <defs>
                                    <filter id="Ellipse_11" x="0" y="0" width="74" height="74"
                                            filterUnits="userSpaceOnUse">
                                        <feOffset dy="3" input="SourceAlpha"/>
                                        <feGaussianBlur stdDeviation="3" result="blur"/>
                                        <feFlood flood-opacity="0.161"/>
                                        <feComposite operator="in" in2="blur"/>
                                        <feComposite in="SourceGraphic"/>
                                    </filter>
                                </defs>
                                <g id="Group_11040" data-name="Group 11040" transform="translate(-586 -1657)">
                                    <g transform="matrix(1, 0, 0, 1, 586, 1657)" filter="url(#Ellipse_11)">
                                        <circle id="Ellipse_11-2" data-name="Ellipse 11" cx="28" cy="28" r="28"
                                                transform="translate(9 6)" fill="#00457b"/>
                                    </g>
                                    <g id="arrow-right_5_" data-name="arrow-right (5)" transform="translate(615 1679)">
                                        <line id="Line_4" data-name="Line 4" x2="22" transform="translate(-3 12)"
                                              fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"
                                              stroke-width="2"/>
                                        <path id="Path_65" data-name="Path 65" d="M12,5l7,7-7,7" fill="none"
                                              stroke="#fff" stroke-linecap="round" stroke-linejoin="round"
                                              stroke-width="2"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="cards_item">
                    <div class="cards_logo">
                        <svg id="Group_50" data-name="Group 50" xmlns="http://www.w3.org/2000/svg" width="56.169"
                             height="59.666" viewBox="0 0 56.169 59.666">
                            <path id="Path_59" data-name="Path 59"
                                  d="M1344.936-987.733h-10.555a9.975,9.975,0,0,0-3.217-8.982,9.435,9.435,0,0,0-6.705-2.494,9.583,9.583,0,0,0-7.411,3.57,9.871,9.871,0,0,0-2.152,7.9h-10.571c0-2.01-.022-3.976.031-5.939.006-.216.41-.508.687-.616.938-.364,1.907-.647,2.854-.988a1.121,1.121,0,0,0,.718-1.735c-.033-.07-.061-.143-.092-.214-.488-1.086-1.5-2.268-1.319-3.231.181-.983,1.522-1.757,2.367-2.61,1.119-1.131,2.271-2.23,3.363-3.387a.988.988,0,0,1,1.408-.232c.844.473,1.733.866,2.6,1.29a1.152,1.152,0,0,0,1.8-.763,11.758,11.758,0,0,0,.693-2.093c.2-1.289.919-1.524,2.1-1.475,2.247.093,4.5.072,6.751,0a1.154,1.154,0,0,1,1.374,1.018c.2.786.51,1.544.777,2.313.424,1.222.93,1.445,2.067.9.8-.384,1.62-.749,2.4-1.184a1,1,0,0,1,1.41.21q2.673,2.759,5.435,5.431a.978.978,0,0,1,.175,1.4c-.448.814-.837,1.66-1.231,2.5-.453.97-.229,1.517.765,1.876.874.316,1.747.643,2.639.9a.986.986,0,0,1,.859,1.131C1344.9-991.416,1344.936-989.6,1344.936-987.733Z"
                                  transform="translate(-1296.539 1009.737)" fill="#00457b"/>
                            <path id="Path_60" data-name="Path 60"
                                  d="M1399.055-876.183c-.028-.626-.057-.967-.057-1.308q0-8.139,0-16.279c.009-4.02,2.156-6.141,6.2-6.146q5.058-.007,10.116,0h1.3c.033.476.078.814.078,1.152,0,6.512-.015,13.023.023,19.535.005.957-.258,1.262-1.234,1.244-3.177-.058-6.357.02-9.534-.035C1403.536-878.065,1401.22-877.84,1399.055-876.183Z"
                                  transform="translate(-1369.169 925.489)" fill="#00457b"/>
                            <path id="Path_61" data-name="Path 61"
                                  d="M1325.57-876.288c-2.2-1.647-4.643-1.8-7.163-1.757-3.143.057-6.288,0-9.432.023-.657,0-.989-.134-.986-.89.025-6.831.019-13.662.026-20.494a2.959,2.959,0,0,1,.078-.431,7.37,7.37,0,0,1,.864-.1c3.687-.007,7.375-.028,11.062,0a5.33,5.33,0,0,1,5.651,5.508c.074,5.821.025,11.644.022,17.466A4.422,4.422,0,0,1,1325.57-876.288Z"
                                  transform="translate(-1299.354 925.511)" fill="#00457b"/>
                            <path id="Path_62" data-name="Path 62"
                                  d="M1424.932-853.144h-1.325c-4.77,0-9.543.086-14.31-.027-3.864-.092-7.521.287-10.5,3.151l-.33-.147a23.081,23.081,0,0,1,.513-4.743,5.06,5.06,0,0,1,5.251-3.618q7.039-.013,14.078,0h1.44v-20.544h5.187Z"
                                  transform="translate(-1368.763 909.499)" fill="#00457b"/>
                            <path id="Path_63" data-name="Path 63"
                                  d="M1270.905-853.036v-25.9h5.11v20.511h1.416c4.615,0,9.234.115,13.844-.037a5.681,5.681,0,0,1,5.987,6.042,26.336,26.336,0,0,0-.013,2.693c-3.1-2.937-6.607-3.429-10.37-3.339-4.884.116-9.773.028-14.659.028Z"
                                  transform="translate(-1270.905 909.391)" fill="#00457b"/>
                            <path id="Path_64" data-name="Path 64"
                                  d="M1370.7-937.919a9.711,9.711,0,0,0-5.5-3.49,1.2,1.2,0,0,1-.737-.808,6.162,6.162,0,0,1,2.763-6.322,6.256,6.256,0,0,1,7,.016,6.3,6.3,0,0,1,2.728,6.345,1.142,1.142,0,0,1-.664.754A9.787,9.787,0,0,0,1370.7-937.919Z"
                                  transform="translate(-1342.621 963.603)" fill="#00457b"/>
                        </svg>
                    </div>
                    <h4 class="cards_title">Nurture existing leads</h4>
                    <div class="cards_description">In addition to helping you generate new leads, we provide all the
                        tools you need to nurture existing leads.
                    </div>
                    <div class="cards_footer">
                        <div class="arrow_item">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                 width="74" height="74" viewBox="0 0 74 74">
                                <defs>
                                    <filter id="Ellipse_11" x="0" y="0" width="74" height="74"
                                            filterUnits="userSpaceOnUse">
                                        <feOffset dy="3" input="SourceAlpha"/>
                                        <feGaussianBlur stdDeviation="3" result="blur"/>
                                        <feFlood flood-opacity="0.161"/>
                                        <feComposite operator="in" in2="blur"/>
                                        <feComposite in="SourceGraphic"/>
                                    </filter>
                                </defs>
                                <g id="Group_11040" data-name="Group 11040" transform="translate(-586 -1657)">
                                    <g transform="matrix(1, 0, 0, 1, 586, 1657)" filter="url(#Ellipse_11)">
                                        <circle id="Ellipse_11-2" data-name="Ellipse 11" cx="28" cy="28" r="28"
                                                transform="translate(9 6)" fill="#00457b"/>
                                    </g>
                                    <g id="arrow-right_5_" data-name="arrow-right (5)" transform="translate(615 1679)">
                                        <line id="Line_4" data-name="Line 4" x2="22" transform="translate(-3 12)"
                                              fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"
                                              stroke-width="2"/>
                                        <path id="Path_65" data-name="Path 65" d="M12,5l7,7-7,7" fill="none"
                                              stroke="#fff" stroke-linecap="round" stroke-linejoin="round"
                                              stroke-width="2"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <!--            -->
            <div class="main_lead-generation">
                <h4 class="main_lead-generation-title">
                    Tech-enabled lead generation
                </h4>
                <p class="main_lead-generation-subtitle">We get you leads and help you close more deals.</p>
                <div class="main_lead-generation-container">
                    <div class="d-flex">
                        <div class="main_lead-generation-item">High-quality, exclusive buyer and seller leads.
                        </div>
                        <div class="main_lead-generation-item">Robust pipeline and recurring deal flow.</div>
                    </div>
                    <div class="d-flex">
                        <div class="main_lead-generation-item">Monetize leads using our AI-based CRM system.</div>
                        <div class="main_lead-generation-item">Add and nurture older leads.</div>

                    </div>
                </div>
                <div class="main_lead-generation-btn-container">
                    <b-button class="main_lead-generation-btn" @click="startGettingLeads()">Start Getting Leads
                    </b-button>
                </div>
            </div>
            <!--     -->
            <div class="why_leads">
                <div class="why_leads-title">Why Choose Rezi Leads?</div>
                <div class="why_leads-cards">
                    <div class="card_item">
                        <div class="card_item-sub">Exclusive leads</div>
                        <div class="card_item-description">Unlike most real estate software, we don’t share one lead
                            with multiple agents.
                        </div>
                    </div>
                    <div class="card_item">
                        <div class="card_item-sub">User-friendly CRM</div>
                        <div class="card_item-description">Other “All-in-one” solutions like to overwhelm you with
                            pointless features.
                            Our CRM is easy-to-use and built with one goal: to help you generate leads with minimal
                            effort.
                        </div>
                    </div>
                    <div class="card_item">
                        <div class="card_item-sub">Fully automated</div>
                        <div class="card_item-description">With Rezi Leads, your lead generation process is fully
                            automated.
                            Just sign up, set a budget, and watch your exclusive leads flood in.
                        </div>
                    </div>
                </div>
                <div class="why_leads-subtitle">Don’t just take our word for it, here’s what our agents say:</div>

            </div>
            <!--            -->
            <div class="testimonials_subtitle">
                <div class="subtitle">By entrepreneurs, for entrepreneurs</div>
                <div class="subtitle_text">We understand how hard it is to grow a real estate business. That’s why we’ve
                    created Rezi Leads, to help you save time, be more efficient, and maximize your earnings potential.
                </div>
            </div>
            <!--     -->
            <div class="main_testimonials-part">
                <div class="testimonials_title">What real estate agents say about Rezi Leads</div>
                <div class="testimonials_carousel">
                    <carousel
                            :dots='false'
                            :autoplay="true"
                            :nav="false"
                            :items="2"
                            :loop="true"
                            :autoplayTimeout="15000"
                            :responsive="{0:{items:1},1500: {items: 1}, 1550: {items: 2}, 1600:{items:2}}"
                    >
                        <div class="carousel_item">
                            <div class="testimonials_img"></div>
                            <div class="testimonials_text">
                                "Rezi Leads helped me build a strong book of business. It didn’t take long for the leads
                                to start coming in. Their lead generation technology and robust CRM provides you with
                                all the tools you need to originate deals"
                            </div>
                            <h4 class="testimonials_name">Becky H.</h4>
                            <div class="testimonials_subtitle">Agent Looking For Leads + Robust CRM</div>
                        </div>
                        <div class="carousel_item">
                            <div class="testimonials_img"></div>
                            <div class="testimonials_text">
                                "I’m so excited about how Rezi Leads will help me do more deals. Even as an agent that
                                has been in the business for many years, I was able to receive a large bucket of leads
                                and was given all the tools needed to nurture these leads. This enabled me to capitalize
                                on both short term opportunities, while developing leads for the future as well."
                            </div>
                            <h4 class="testimonials_name">Trey C.</h4>
                            <div class="testimonials_subtitle">Seasoned Agent With 10+ Years of Experience</div>
                        </div>
                        <div class="carousel_item">
                            <div class="testimonials_img"></div>
                            <div class="testimonials_text">
                                “Rezi Leads is helping me generate dealflow. As a new agent, ReziLeads has saved me
                                hours with its lead generation technology and supporting CRM.“
                            </div>
                            <h4 class="testimonials_name">Jen S.</h4>
                            <div class="testimonials_subtitle">New Agent</div>
                        </div>
                        <template slot="next">
                            <div class="next_btn"><span class="next"></span></div>
                        </template>
                    </carousel>
                </div>
            </div>
            <!--     -->
            <div class="testimonials_subtitle">
                <div class="subtitle">Our agents have seen over 398% ROI and saved more than 20 hours a week,
                    and you can do the same too.
                </div>
                <div class="btn-container">
                    <b-button class="info-btn" @click="startGettingLeads()">Start Getting Leads</b-button>
                </div>
            </div>
            <!--            -->
            <div class="main_leads-container">
                <div class="main_leads-links-content">
                    <div class="main_leads-links">
                        <div class="main_leads-link-item">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
                                    <g id="Group_11048" data-name="Group 11048" transform="translate(-217.813 -2593)">
                                        <circle id="Ellipse_22" data-name="Ellipse 22" cx="27" cy="27" r="27"
                                                transform="translate(217.813 2593)" fill="#409edc"/>
                                        <g id="Group_424" data-name="Group 424"
                                           transform="translate(-135.791 3022.836)">
                                            <path id="Path_238" data-name="Path 238"
                                                  d="M380.648-392.367a10.68,10.68,0,0,0,10.276-13.361c-.236-.921.091-1.559.837-1.63.6-.057,1.014.254,1.193.959a12.262,12.262,0,0,1-2.224,11.138c-3.333,4.211-7.778,5.875-13.006,4.654s-8.506-4.685-9.659-9.948a12.774,12.774,0,0,1,10.373-15.1,12.521,12.521,0,0,1,9.117,1.88,1.725,1.725,0,0,1,.629.888.881.881,0,0,1-.487,1.006,1.043,1.043,0,0,1-1.178-.046,10.657,10.657,0,0,0-3.658-1.54,10.659,10.659,0,0,0-12.876,9.583A10.677,10.677,0,0,0,380.648-392.367Z"
                                                  transform="translate(0)" fill="#fff"/>
                                            <path id="Path_239" data-name="Path 239"
                                                  d="M448.127-383.212c.886-.893,1.727-1.747,2.574-2.6q4.062-4.066,8.128-8.128a3.959,3.959,0,0,1,.362-.344,1.05,1.05,0,0,1,1.373.062,1.043,1.043,0,0,1,.154,1.365,2.908,2.908,0,0,1-.366.408q-5.617,5.62-11.236,11.238c-.776.776-1.246.776-2.023,0-1.308-1.307-2.62-2.61-3.921-3.924a1.069,1.069,0,0,1,.341-1.852,1.084,1.084,0,0,1,1.172.35q1.514,1.525,3.039,3.039C447.839-383.479,447.961-383.369,448.127-383.212Z"
                                                  transform="translate(-67.497 -19.199)" fill="#fff"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <span>Seller Leads</span>
                        </div>
                        <div class="main_leads-link-item">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
                                    <g id="Group_11048" data-name="Group 11048" transform="translate(-217.813 -2593)">
                                        <circle id="Ellipse_22" data-name="Ellipse 22" cx="27" cy="27" r="27"
                                                transform="translate(217.813 2593)" fill="#409edc"/>
                                        <g id="Group_424" data-name="Group 424"
                                           transform="translate(-135.791 3022.836)">
                                            <path id="Path_238" data-name="Path 238"
                                                  d="M380.648-392.367a10.68,10.68,0,0,0,10.276-13.361c-.236-.921.091-1.559.837-1.63.6-.057,1.014.254,1.193.959a12.262,12.262,0,0,1-2.224,11.138c-3.333,4.211-7.778,5.875-13.006,4.654s-8.506-4.685-9.659-9.948a12.774,12.774,0,0,1,10.373-15.1,12.521,12.521,0,0,1,9.117,1.88,1.725,1.725,0,0,1,.629.888.881.881,0,0,1-.487,1.006,1.043,1.043,0,0,1-1.178-.046,10.657,10.657,0,0,0-3.658-1.54,10.659,10.659,0,0,0-12.876,9.583A10.677,10.677,0,0,0,380.648-392.367Z"
                                                  transform="translate(0)" fill="#fff"/>
                                            <path id="Path_239" data-name="Path 239"
                                                  d="M448.127-383.212c.886-.893,1.727-1.747,2.574-2.6q4.062-4.066,8.128-8.128a3.959,3.959,0,0,1,.362-.344,1.05,1.05,0,0,1,1.373.062,1.043,1.043,0,0,1,.154,1.365,2.908,2.908,0,0,1-.366.408q-5.617,5.62-11.236,11.238c-.776.776-1.246.776-2.023,0-1.308-1.307-2.62-2.61-3.921-3.924a1.069,1.069,0,0,1,.341-1.852,1.084,1.084,0,0,1,1.172.35q1.514,1.525,3.039,3.039C447.839-383.479,447.961-383.369,448.127-383.212Z"
                                                  transform="translate(-67.497 -19.199)" fill="#fff"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <span>Buyer Leads</span>
                        </div>
                        <div class="main_leads-link-item">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
                                    <g id="Group_11048" data-name="Group 11048" transform="translate(-217.813 -2593)">
                                        <circle id="Ellipse_22" data-name="Ellipse 22" cx="27" cy="27" r="27"
                                                transform="translate(217.813 2593)" fill="#409edc"/>
                                        <g id="Group_424" data-name="Group 424"
                                           transform="translate(-135.791 3022.836)">
                                            <path id="Path_238" data-name="Path 238"
                                                  d="M380.648-392.367a10.68,10.68,0,0,0,10.276-13.361c-.236-.921.091-1.559.837-1.63.6-.057,1.014.254,1.193.959a12.262,12.262,0,0,1-2.224,11.138c-3.333,4.211-7.778,5.875-13.006,4.654s-8.506-4.685-9.659-9.948a12.774,12.774,0,0,1,10.373-15.1,12.521,12.521,0,0,1,9.117,1.88,1.725,1.725,0,0,1,.629.888.881.881,0,0,1-.487,1.006,1.043,1.043,0,0,1-1.178-.046,10.657,10.657,0,0,0-3.658-1.54,10.659,10.659,0,0,0-12.876,9.583A10.677,10.677,0,0,0,380.648-392.367Z"
                                                  transform="translate(0)" fill="#fff"/>
                                            <path id="Path_239" data-name="Path 239"
                                                  d="M448.127-383.212c.886-.893,1.727-1.747,2.574-2.6q4.062-4.066,8.128-8.128a3.959,3.959,0,0,1,.362-.344,1.05,1.05,0,0,1,1.373.062,1.043,1.043,0,0,1,.154,1.365,2.908,2.908,0,0,1-.366.408q-5.617,5.62-11.236,11.238c-.776.776-1.246.776-2.023,0-1.308-1.307-2.62-2.61-3.921-3.924a1.069,1.069,0,0,1,.341-1.852,1.084,1.084,0,0,1,1.172.35q1.514,1.525,3.039,3.039C447.839-383.479,447.961-383.369,448.127-383.212Z"
                                                  transform="translate(-67.497 -19.199)" fill="#fff"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <span>Monetize Leads Using our AI Based CRM System</span>
                        </div>
                        <div class="main_leads-link-item">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
                                    <g id="Group_11048" data-name="Group 11048" transform="translate(-217.813 -2593)">
                                        <circle id="Ellipse_22" data-name="Ellipse 22" cx="27" cy="27" r="27"
                                                transform="translate(217.813 2593)" fill="#409edc"/>
                                        <g id="Group_424" data-name="Group 424"
                                           transform="translate(-135.791 3022.836)">
                                            <path id="Path_238" data-name="Path 238"
                                                  d="M380.648-392.367a10.68,10.68,0,0,0,10.276-13.361c-.236-.921.091-1.559.837-1.63.6-.057,1.014.254,1.193.959a12.262,12.262,0,0,1-2.224,11.138c-3.333,4.211-7.778,5.875-13.006,4.654s-8.506-4.685-9.659-9.948a12.774,12.774,0,0,1,10.373-15.1,12.521,12.521,0,0,1,9.117,1.88,1.725,1.725,0,0,1,.629.888.881.881,0,0,1-.487,1.006,1.043,1.043,0,0,1-1.178-.046,10.657,10.657,0,0,0-3.658-1.54,10.659,10.659,0,0,0-12.876,9.583A10.677,10.677,0,0,0,380.648-392.367Z"
                                                  transform="translate(0)" fill="#fff"/>
                                            <path id="Path_239" data-name="Path 239"
                                                  d="M448.127-383.212c.886-.893,1.727-1.747,2.574-2.6q4.062-4.066,8.128-8.128a3.959,3.959,0,0,1,.362-.344,1.05,1.05,0,0,1,1.373.062,1.043,1.043,0,0,1,.154,1.365,2.908,2.908,0,0,1-.366.408q-5.617,5.62-11.236,11.238c-.776.776-1.246.776-2.023,0-1.308-1.307-2.62-2.61-3.921-3.924a1.069,1.069,0,0,1,.341-1.852,1.084,1.084,0,0,1,1.172.35q1.514,1.525,3.039,3.039C447.839-383.479,447.961-383.369,448.127-383.212Z"
                                                  transform="translate(-67.497 -19.199)" fill="#fff"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <span>Nurture Leads</span>
                        </div>
                    </div>

                </div>
                <div class="main_leads-dashboard-img">


                </div>
            </div>
            <!--     -->
            <div class="main_info-head" ref="info_container"></div>
            <!--            -->
            <div class="main_info-container">
                <h5 class="main_info-title">Start getting leads and grow your real estate business today!</h5>
                <p class="main_info-subtitle">Excess demand has created a limited number of spots. We will stop
                    accepting new users soon. </p>
                <div class="main_info-box">
                    <div class="main_info-box-title">brokers / agents</div>
                    <div class="main_info-box-checkboxes">
                        <b-form-radio-group v-model="broker_agent" :options="options" name="radio-validation">
                        </b-form-radio-group>
                    </div>

                    <div class="main_info-box_row-item">
                        <div role="group" class="main_info-box-input">
                            <label>First Name</label>
                            <b-form-input
                                    v-model="leads.firstName"
                                    trim
                            >
                            </b-form-input>
                        </div>
                        <div role="group" class="main_info-box-input">
                            <label>Last Name</label>
                            <b-form-input
                                    v-model="leads.lastName"
                                    trim
                            >
                            </b-form-input>
                        </div>
                    </div>

                    <div class="main_info-box_row-item">
                        <div role="group" class="main_info-box-input">
                            <label>Email Address</label>
                            <b-form-input
                                    v-model="leads.email"
                                    type="email"
                                    trim
                            >
                            </b-form-input>
                            <span v-if="homePageErrors['email']"
                                  class="text_danger mt-1">{{homePageErrors['email'][0]}}</span>
                        </div>
                        <div role="group" class="main_info-box-input">
                            <label>Phone</label>
                            <b-form-input
                                    v-model="leads.phone"
                                    type="number"
                                    trim
                            >
                            </b-form-input>
                            <span v-if="homePageErrors['phone']"
                                  class="text_danger mt-1">{{homePageErrors['phone'][0]}}</span>
                        </div>
                    </div>
                    <div class="main_info-box_row-item">
                        <div role="group" class="main_info-box-input">
                            <label>City</label>
                            <b-form-input
                                    v-model="leads.city"
                                    trim
                            >
                            </b-form-input>
                            <span v-if="homePageErrors['city']"
                                  class="text_danger mt-1">{{homePageErrors['city'][0]}}</span>
                        </div>
                        <div role="group" class="main_info-box-input">
                            <label>Zip Code</label>
                            <b-form-input
                                    v-model="leads.zip_code"
                                    type="number"
                                    trim
                            >
                            </b-form-input>
                            <span v-if="homePageErrors['zip_code']" class="text_danger mt-1">{{homePageErrors['zip_code'][0]}}</span>
                        </div>
                    </div>

                    <div class="main_info-btn-container">
                        <b-button class="main_info-btn" @click="signUp()">Start Getting Leads</b-button>
                    </div>

                </div>

            </div>
        </div>
<!--        <div class="continue-mob">-->
<!--            <div class="header_cont">-->
<!--                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 255.675 168.125">-->
<!--                    <g id="Group_1" data-name="Group 1" transform="translate(-0.109)">-->
<!--                        <path id="Path_1" data-name="Path 1"-->
<!--                              d="M127.9,0h96.9c7.4,0,8.8,1.4,8.8,8.7V125.1c0,7.3-1.5,8.8-8.8,8.8H30.9c-7.3,0-8.8-1.5-8.8-8.8V8.7C22.2,1.4,23.6,0,31,0Zm-.5,115.3c27.7,0,55.3-.1,83,.1,3.7,0,4.8-.9,4.8-4.7-.2-29.2-.2-58.3,0-87.5,0-3.9-1.1-5-5-5q-82.2.15-164.4,0c-3.9,0-5,1.1-5,5q.3,43.5,0,87c0,4.2,1.1,5.2,5.3,5.2C73.1,115.2,100.2,115.3,127.4,115.3Z"/>-->
<!--                        <path id="Path_2" data-name="Path 2"-->
<!--                              d="M128.1,143H250.6c5.7,0,6.2.6,3.9,5.9-5.3,12.4-15.3,17.9-28.1,19.1-2.2.2-4.3.1-6.5.1H36c-6.4,0-12.6-.5-18.6-3A29.238,29.238,0,0,1,1.2,148.4c-2-4.9-1.5-5.4,3.6-5.4H128.1Zm-.2,8.1c-5.3,0-10.7.2-16-.1-3.5-.2-3.2,2.1-3,4.2.1,2.1-1,4.8,3,4.7,10.6-.2,21.3-.2,31.9,0,4,.1,2.9-2.6,3-4.7s.5-4.4-3-4.2C138.5,151.3,133.2,151.1,127.9,151.1Z"/>-->
<!--                    </g>-->
<!--                </svg>-->
<!--                <div class="info">-->
<!--                    <div class="info_logo"></div>-->
<!--                    <h1 class="info_text">works better if you switch to our web site</h1>-->
<!--                </div>-->

<!--            </div>-->
<!--            <div class="button-cont">-->
<!--                <b-button class="continue_btn">-->
<!--                    Continue-->
<!--                </b-button>-->
<!--                <a href="#" class="continue_text">Continue with web</a>-->
<!--            </div>-->
<!--        </div>-->
        <!--        modal start-->
        <b-modal ref="video-modal" centered hide-header hide-footer hide-header-close>
            <div class="video_modal_content">
                <b-icon icon="x" class="x_btn" aria-hidden="true" @click="openFrame()"></b-icon>
                <div class="video_container">
                    <video id="videoElement" controls poster="velocity-thumbnail.jpg" width="400" height="300">
                        <source src="@/assets/videos/Rezi_Leads.mp4" type="video/mp4">
                        <p>Sorry, there's a problem playing this video. Please try using a different browser.</p>
                    </video>
                </div>
            </div>
        </b-modal>
        <!--        modal end-->
        <Footer/>
    </div>
</template>

<script>
    import NavBar from "../components/NavBar";
    import Footer from "../components/Footer";
    import carousel from 'vue-owl-carousel'
    import {mapActions} from "vuex";

    export default {
        name: 'Home',
        data() {
            return {
                broker_agent: '0',
                options: [
                    {text: '1 - Just me', value: '0'},
                    {text: '2-5', value: '1'},
                    {text: '6-9', value: '2'},
                    {text: '10+', value: '3'}
                ],
                leads: {
                    firstName: null,
                    lastName: null,
                    email: null,
                    phone: null,
                    city: null,
                    zip_code: null,
                    count_of_people: null
                },
                videoElement: null,
                paused: null,
                homePageErrors: []
            }
        },
        components: {
            NavBar,
            Footer,
            carousel
        },
        methods: {
            ...mapActions(['interestedUser']),
            startGettingLeads() {
                const container = this.$refs.info_container;
                container.scrollIntoView({behavior: "smooth", block: "start"});
            },
            playVideo() {
                this.openFrame()
                setTimeout(() => {
                    this.play()
                }, 1000)

            },
            openFrame() {
                this.$refs['video-modal'].toggle('#video-btn')
            },
            play() {
                let el = document.getElementById('videoElement')
                el.play();
            },
            signUp() {

                this.leads.count_of_people = this.broker_agent
                this.interestedUser(this.leads)
                    .then(res => {
                        if (res.status === 200) {
                            this.$store.commit('INTEREST_USER', res.data.success.id)
                            // this.$router.push('/register')
                        } else {
                            this.homePageErrors = res.response.data.error
                        }
                    })
                this.$store.commit('REGISTER_USER', this.leads)
                this.$router.push('/pricing')

            }
        }
    }
</script>

<style scoped lang="scss">
    .main_container {
        width: 100%;

        /*& .continue-mob {*/
        /*    display: none;*/
        /*}*/

        @media screen and (max-width: 768px) {
            & .header_cont {
                display: flex;
                align-items: center;

                & svg {
                    margin-right: 15px;
                }

                & .info {
                    display: flex;
                    align-items: flex-end;
                    @media screen and (max-width: 425px) {
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    &_logo {
                        background: url("~@/assets/images/Logo.svg") center no-repeat;
                        background-size: contain;
                        width: 100px;
                        height: 40px;
                    }

                    &_text {
                        margin-left: 10px;
                        font-size: 22px;
                        color: $black;
                        @media screen and (max-width: 660px) {
                            font-size: 18px;
                        }
                        @media screen and (max-width: 560px) {
                            font-size: 14px;
                            margin: 0;
                        }
                        @media screen and (max-width: 560px) {
                            font-size: 12px;
                        }

                    }
                }
            }
            & .continue-mob {
                display: flex !important;
                flex-direction: column;
                align-items: center;
                position: fixed;
                bottom: 0;
                background: #EFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 3px 0px 6px #00000029;
                z-index: 99;
                width: 100%;
                padding: 15px 25px;
                @media screen and (max-width: 425px) {
                    padding: 15px;
                }

                & .button-cont {
                    display: flex;
                    flex-direction: column;
                    padding: 10px 0;

                    & .continue_btn {
                        width: 220px !important;
                        margin-top: 15px;
                        background: $light_blue;
                        color: $white;
                        font-size: 18px;
                        font-weight: 500;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 14px 42px;
                        border: none;
                        box-shadow: 0 3px 6px #00000029;
                        border-radius: 10px;
                    }

                    & .continue_text {
                        font: normal normal 600 18px/21px Poppins;
                        letter-spacing: 0;
                        color: #ADC7CE;
                        text-align: center;
                        display: block;
                        margin-top: 15px;
                    }
                }
            }

        }

    }

    .main {
        &_home {
            width: 100%;
            /*height: calc(100vh - 80px);*/
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            & .testimonials_subtitle {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                padding: 0 0 25px;
                @media screen and (max-width: 425px) {
                    padding: 0 0 10px;
                }

                & .subtitle {
                    padding: 15px 0 20px;
                    font-size: 46px;
                    color: $main_blue;
                    font-weight: 600;
                    text-align: center;
                    max-width: 1010px;
                    @media screen and (max-width: 1280px) {
                        font-size: 40px;
                    }
                    @media screen and (max-width: 1024px) {
                        font-size: 28px;
                        max-width: 760px;
                    }
                    @media screen and (max-width: 425px) {
                        font-size: 24px;
                        line-height: 30px;
                        padding: 5px 10px 10px;
                        max-width: 380px;
                    }

                    &_text {
                        font-size: 20px;
                        color: $grey;
                        max-width: 1200px;
                        width: 100%;
                        text-align: center;
                        @media screen and (max-width: 1280px) {
                            max-width: 1000px;
                        }
                        @media screen and (max-width: 1024px) {
                            max-width: 850px;
                        }
                        @media screen and (max-width: 425px) {
                            max-width: 360px;
                            font-size: 14px;
                        }
                    }

                    &_data {
                        font-size: 20px;
                        font-weight: 500;
                        color: $light_blue;
                        @media screen and (max-width: 425px) {
                            font-size: 18px;
                        }
                    }

                    &_sub {
                        padding-top: 15px;
                        color: $main_blue;
                        font-weight: 600;
                        font-size: 24px;
                        @media screen and (max-width: 425px) {
                            font-size: 22px;
                        }

                    }
                }

                & .btn-container {
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    padding: 15px 0;

                    & .info-btn {
                        background: $main_blue;
                        box-shadow: 0 3px 6px #00000029;
                        border-radius: 10px;
                        border: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 246px;
                        height: 58px;
                        color: $white;
                        font-size: 16px;
                        font-weight: 500;
                        @media screen and (max-width: 768px) {
                            font-size: 15px;
                            width: 220px;
                            height: 45px;
                        }

                        &:hover {
                            background: $main_blue;
                        }

                        &:focus {
                            background: $main_blue;
                            box-shadow: none;
                        }
                    }
                }
            }

            & .why_leads {
                padding: 20px 0;
                display: flex;
                flex-direction: column;
                align-items: center;

                &-title {
                    font-size: 46px;
                    color: $main_blue;
                    font-weight: 600;
                    @media screen and (max-width: 1280px) {
                        font-size: 40px;
                    }
                    @media screen and (max-width: 1024px) {
                        font-size: 28px;
                    }
                    @media screen and (max-width: 425px) {
                        font-size: 24px;
                    }
                }
                &-cards {
                    max-width: 1640px;
                    display: flex;
                    justify-content: space-between;
                    padding: 30px 0;
                    @media screen and (max-width: 1024px) {
                        max-width: 800px;
                        flex-wrap: wrap;
                        justify-content: center;
                    }
                    @media screen and (max-width: 1024px) {
                        max-width: 700px;
                    }
                    & .card_item {
                        width: 400px;
                        margin: 0 10px;
                        border-radius: 10px;
                        background: $light_blue;
                        color: $white;
                        padding: 20px;
                        @media screen and (max-width: 1280px) {
                                width: 360px;
                        }
                        @media screen and (max-width: 1024px) {
                            margin: 10px;
                        }
                        &-sub {
                            font-size: 24px;
                            letter-spacing: 1px;
                            font-weight: 600;
                            text-decoration: underline;
                            @media screen and (max-width: 1024px) {
                                font-size: 20px;
                            }

                        }
                        &-description {
                            margin-top: 30px;
                            @media screen and (max-width: 1280px) {
                                margin-top: 15px;
                            }
                            @media screen and (max-width: 1024px) {
                                font-size: 14px;
                            }

                        }
                    }
                }
                &-subtitle {
                    font-size: 38px;
                    color: $main_blue;
                    font-weight: 600;
                    @media screen and (max-width: 1024px) {
                        font-size: 24px;
                    }
                    @media screen and (max-width: 425px) {
                        font-size: 20px;
                        max-width: 400px;
                        text-align: center;
                    }
                }
            }
        }

        &_title_content {
            display: flex;
            padding-top: 80px;
            @media screen and (max-width: 1700px) {
                padding: 80px 60px 0;
            }
            @media screen and (max-width: 1200px) {
                padding: 80px 0 0;
            }
            @media screen and (max-width: 768px) {
                flex-direction: column;
            }
        }

        &_title-part {
            display: flex;
            flex-direction: column;
            max-width: 675px;
            width: 100%;
            @media screen and (max-width: 1700px) {
                max-width: 600px;
            }
            @media screen and (max-width: 1600px) {
                max-width: 580px;
            }
            @media screen and (max-width: 1200px) {
                max-width: 420px;
            }
            @media screen and (max-width: 768px) {
                max-width: none;
                padding: 0 30px;
                align-items: center;
            }
        }

        &_title {
            color: $main_blue;
            font-size: 60px;
            font-weight: bold;
            line-height: 64px;
            padding-top: 85px;
            @media screen and (max-width: 1700px) {
                font-size: 52px;
            }
            @media screen and (max-width: 1600px) {
                font-size: 48px;
            }
            @media screen and (max-width: 1200px) {
                font-size: 30px;
                line-height: 34px;
                padding-top: 65px;
            }
            @media screen and (max-width: 768px) {
                padding-top: 30px;
                font-size: 28px;
            }
            @media screen and (max-width: 425px) {
                font-size: 20px;
                line-height: 26px;
                padding-top: 20px;
            }
            @media screen and (max-width: 320px) {
                font-size: 18px;
                line-height: 22px;
                text-align: center;

            }

        }

        &_description {
            color: $grey;
            font-size: 20px;
            padding: 15px 0;
            @media screen and (max-width: 1700px) {
                font-size: 18px;
            }
            @media screen and (max-width: 1600px) {
                font-size: 16px;
            }
            @media screen and (max-width: 768px) {
                font-size: 14px;
                padding: 15px 30px;
                text-align: center;
            }
            @media screen and (max-width: 320px) {
                font-size: 12px;
            }
        }

        &_buttons-part {
            padding-top: 50px;
            display: flex;
            align-items: center;
            @media screen and (max-width: 1200px) {
                padding-top: 45px;
            }
            @media screen and (max-width: 768px) {
                padding-top: 15px;
            }
            @media screen and (max-width: 425px) {
                flex-direction: column;
                padding-top: 5px;
            }

            & .start_btn {
                background: $light_blue;
                color: $white;
                font-size: 18px;
                font-weight: 500;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 14px 42px;
                border: none;
                box-shadow: 0 3px 6px #00000029;
                border-radius: 10px;
                @media screen and (max-width: 1200px) {
                    font-size: 16px;
                    padding: 14px 32px;
                }
                @media screen and (max-width: 768px) {
                    padding: 8px 20px;
                    font-size: 14px;
                }
            }

            & .main_play-btn {
                margin: 0 20px 0 30px;
                width: 56px;
                height: 56px;
                cursor: pointer;
                @media screen and (max-width: 768px) {
                    margin: 0 15px 0 20px;
                }
                @media screen and (max-width: 425px) {
                    margin: 10px 15px 5px 20px;
                }

                &:hover svg g g {
                    fill: $light_blue;
                }

                &:hover svg path {
                    fill: $white;
                }
            }
        }

        &_cards {
            width: 465px;
            position: relative;
            @media screen and (max-width: 1700px) {
                width: 300px;
            }
            @media screen and (max-width: 1200px) {
                width: 120px;
            }
            @media screen and (max-width: 768px) {
                display: none;
            }

            & .cards_item {
                position: absolute;
                top: 0;
                right: -125px;
                width: 800px;
                height: 100%;
                background: url("~@/assets/images/users_cards.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                @media screen and (max-width: 1700px) {
                    right: -100px;
                    width: 590px;
                }
                @media screen and (max-width: 1600px) {
                    right: -160px;
                    width: 500px;
                }
                @media screen and (max-width: 1440px) {
                    right: -100px;
                    width: 440px;
                }
                @media screen and (max-width: 1200px) {
                    right: -150px;
                    top: 60px;
                    width: 400px;
                }

            }
        }

        &_images {
            width: 500px;
            height: 680px;
            background: url("~@/assets/images/houses.png");
            background-repeat: no-repeat;
            background-position: center;
            @media screen and (max-width: 1700px) {
                width: 500px;
                background-size: contain;
            }
            @media screen and (max-width: 1600px) {
                height: 640px;
            }
            @media screen and (max-width: 1400px) {
                height: 600px;
            }
            @media screen and (max-width: 1400px) {
                width: 340px;
                height: 470px;
            }
            @media screen and (max-width: 768px) {
                height: 430px;
                margin: 0 auto;
            }
            @media screen and (max-width: 320px) {
                width: 280px;
                height: 380px;
            }
        }

        /////////////

        &_statistic-part {
            width: 100%;
            height: 500px;
            background-image: url("~@/assets/images/blue_house_background.svg");
            background-position: center;
            background-repeat: no-repeat;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            margin-bottom: 20px;
            @media screen and (max-width: 1440px) {
                height: 450px;
            }
            @media screen and (max-width: 768px) {
                height: 350px;
            }
            @media screen and (max-width: 425px) {
                flex-direction: column;
            }

            & .statistic_item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                @media screen and (max-width: 425px) {
                    &.even {
                        margin-right: 80px;
                    }
                    &.odd {
                        margin-left: 80px;
                    }
                }

                & .item {
                    &_points {
                        color: $white;
                        font-size: 60px;
                        line-height: 70px;
                        @media screen and (max-width: 1600px) {
                            font-size: 56px;
                        }
                        @media screen and (max-width: 1440px) {
                            font-size: 46px;
                            line-height: 52px;
                        }
                        @media screen and (max-width: 1280px) {
                            font-size: 40px;
                            line-height: 40px;
                        }
                        @media screen and (max-width: 1024px) {
                            font-size: 32px;
                            line-height: 36px;
                        }
                        @media screen and (max-width: 768px) {
                            font-size: 28px;
                            line-height: 30px;
                            max-width: 150px;
                            height: 75px;
                            text-align: center;
                        }
                        @media screen and (max-width: 425px) {
                            font-size: 22px;
                            line-height: 20px;
                            max-width: unset;
                            height: 22px;
                        }
                    }

                    &_text {
                        color: $white;
                        font-size: 20px;
                        padding: 10px 0 22px;
                        @media screen and (max-width: 1280px) {
                            font-size: 18px;
                        }
                        @media screen and (max-width: 1024px) {
                            font-size: 16px;
                        }
                        @media screen and (max-width: 768px) {
                            font-size: 14px;
                            padding: 8px 0 12px;
                        }
                        @media screen and (max-width: 425px) {
                            padding: 4px 0 8px;
                            font-size: 12px;
                        }
                    }

                    &_line {
                        width: 130px;
                        height: 4px;
                        background: #409EDC 0 0 no-repeat padding-box;
                        border-radius: 5px;
                        @media screen and (max-width: 768px) {
                            width: 90px;
                        }
                        @media screen and (max-width: 425px) {
                            width: 80px;
                            height: 3px;
                        }
                    }
                }
            }
        }

        /////////////
        &_cards-part {
            width: 100%;
            padding: 80px 0;
            display: flex;
            justify-content: center;
            @media screen and (max-width: 1700px) {
                padding: 90px 60px;
            }
            @media screen and (max-width: 768px) {
                padding: 40px 30px;
                flex-direction: column;
                align-items: center;
            }
            @media screen and (max-width: 320px) {
                padding: 10px;
            }

            & .cards {
                &_item {
                    padding: 34px 20px 10px 34px;
                    background: $light_grey;
                    margin: 0 5px;
                    width: 540px;
                    border-radius: 10px;
                    display: flex;
                    flex-direction: column;
                    cursor: pointer;
                    @media screen and (max-width: 768px) {
                        margin: 15px 0;
                    }
                    @media screen and (max-width: 425px) {
                        width: 350px;
                    }
                    @media screen and (max-width: 320px) {
                        width: 280px;
                        padding: 20px 15px 10px 25px;
                    }

                    &:hover {
                        background: $light_blue;
                    }

                    &:hover .cards_logo svg path {
                        fill: $white;
                    }

                    &:hover .cards_title,
                    &:hover .cards_description {
                        color: $white;
                    }

                    &:hover .cards_footer .arrow_item svg circle {
                        fill: $white;
                    }

                    &:hover .cards_footer .arrow_item svg line {
                        stroke: $main_blue;
                    }

                    &:hover .cards_footer .arrow_item svg path {
                        stroke: $main_blue;
                    }

                }

                &_logo {

                }

                &_title {
                    color: $main_blue;
                    font-size: 25px;
                    font-weight: bold;
                    padding: 20px 0;
                    @media screen and (max-width: 1440px) {
                        font-size: 23px;
                        padding: 15px 0;
                        margin-bottom: 0;
                    }
                    @media screen and (max-width: 1200px) {
                        font-size: 20px;
                    }
                }

                &_description {
                    color: $main_blue;
                    font-size: 16px;
                    line-height: 26px;
                    padding-right: 10px;
                    height: 70px;
                    @media screen and (max-width: 1200px) {
                        font-size: 15px;
                        line-height: 22px;
                    }
                }

                &_footer {
                    display: flex;
                    justify-content: flex-end;
                    padding-top: 30px;

                    & .arrow_item {
                        & svg {
                            @media screen and (max-width: 1440px) {
                                width: 66px;
                                height: 66px;
                            }
                        }

                    }
                }
            }
        }

        /////////////
        &_testimonials-part {
            display: flex;
            max-width: 1640px;
            width: 100%;
            position: relative;
            @media screen and (max-width: 1700px) {
                padding: 0 60px;
            }
            @media screen and (max-width: 768px) {
                flex-direction: column;
                padding: 0 40px;
                max-width: 680px;
            }
            @media screen and (max-width: 425px) {
                padding: 0;
                max-width: 400px;
            }

            & .testimonials_title {
                padding-top: 80px;
                width: 360px;
                color: $main_blue;
                font-size: 56px;
                line-height: 70px;
                font-weight: 500;
                @media screen and (max-width: 1700px) {
                    font-size: 48px;
                }
                @media screen and (max-width: 1440px) {
                    font-size: 46px;
                }
                @media screen and (max-width: 1200px) {
                    font-size: 40px;
                }
                @media screen and (max-width: 1024px) {
                    width: 270px;
                    font-size: 34px;
                    line-height: 48px;
                    padding-top: 120px;
                }
                @media screen and (max-width: 768px) {
                    width: 100%;
                    padding: 20px 0;
                    text-align: center;
                    font-size: 28px;
                }
                @media screen and (max-width: 425px) {
                    font-size: 22px;
                    padding: 10px;
                    line-height: 36px;
                }
            }

            & .testimonials_carousel {
                max-width: 1200px;
                width: 100%;
                overflow: hidden;
                display: flex;
                padding: 0 115px 0 80px;
                @media screen and (max-width: 1440px) {
                    max-width: 960px;
                }
                @media screen and (max-width: 1024px) {
                    max-width: 560px;
                    padding: 0 110px 0 60px;
                }
                @media screen and (max-width: 768px) {
                    padding: 0;
                    max-width: 480px;
                }

                ::v-deep .owl-carousel .owl-item {
                    opacity: 0;
                }

                ::v-deep .owl-carousel .owl-item.active {
                    opacity: 1 !important;
                }

                & .next_btn {
                    position: absolute;
                    top: calc(50% - 36px);
                    right: 15px;
                    width: 82px;
                    height: 82px;
                    cursor: pointer;
                    background-position: center;
                    background-repeat: no-repeat;
                    z-index: 2;
                    background: url("~@/assets/images/right_arrow.svg");
                    @media screen and (max-width: 1700px) {
                        right: 60px;
                    }
                    @media screen and (max-width: 425px) {
                        right: 25px;
                        bottom: -5px;
                        top: unset;
                        width: 40px;
                        height: 40px;
                        background-size: cover;
                    }

                    & .next {
                        width: 100%;
                        height: 100%;
                    }
                }

                & .carousel_item {
                    padding: 40px 42px;
                    width: 520px;
                    margin: 0 44px;
                    border-left: 1px solid #96A7B4;
                    @media screen and (max-width: 1700px) {
                        width: 480px;
                    }
                    @media screen and (max-width: 425px) {
                        width: 360px;
                        padding: 20px;
                        margin: 0 30px;
                    }
                    @media screen and (max-width: 320px) {
                        width: 300px;
                        margin: 0 15px;
                    }

                    & .testimonials {
                        &_img {
                            width: 58px;
                            height: 45px;
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: contain;
                            background: url("~@/assets/images/testimonials.svg");
                            @media screen and (max-width: 425px) {
                                width: 40px;
                                height: 30px;
                                background-position: center;
                                background-repeat: no-repeat;
                                background-size: cover !important;
                            }
                        }

                        &_text {
                            padding: 40px 10px 20px 0;
                            color: $grey;
                            font-size: 18px;
                            min-height: 280px;
                            height: 100%;
                            @media screen and (max-width: 425px) {
                                padding: 20px 10px 15px 0;
                                font-size: 14px;
                                min-height: 200px;
                            }
                        }

                        &_name {
                            color: $main_blue;
                            font-size: 20px;
                            font-weight: bold;
                            @media screen and (max-width: 425px) {
                                font-size: 18px;
                            }
                        }

                        &_subtitle {
                            color: $light_blue;
                            font-weight: bold;
                            font-size: 19px;
                            @media screen and (max-width: 425px) {
                                font-size: 16px;
                            }
                        }
                    }

                }
            }
        }

        /////////////
        &_leads {
            &-container {
                max-width: 1640px;
                width: 100%;
                display: flex;
                align-items: center;
                padding: 90px 0;
                @media screen and (max-width: 1700px) {
                    padding: 90px 60px;
                }
                @media screen and (max-width: 1200px) {
                    padding: 60px;
                }
                @media screen and (max-width: 1024px) {
                    padding: 40px 0;
                    justify-content: center;
                    max-width: 900px;
                }
                @media screen and (max-width: 768px) {
                    flex-direction: column;
                    padding: 20px 0;
                }
            }

            &-links-content {
                padding-left: 90px;
                @media screen and (max-width: 1440px) {
                    padding-left: 60px;
                }
                @media screen and (max-width: 1440px) {
                    padding-left: 20px;
                }
                @media screen and (max-width: 768px) {
                    padding: 20px 0;
                }
                @media screen and (max-width: 320px) {
                    padding: 20px;
                }
            }

            &-link-item {
                padding: 20px 0;
                color: $main_blue;
                font-size: 25px;
                font-weight: bold;
                max-width: 520px;
                display: flex;
                align-items: center;
                line-height: 28px;
                @media screen and (max-width: 1440px) {
                    max-width: 500px;
                    font-size: 22px;
                }
                @media screen and (max-width: 1200px) {
                    max-width: 470px;
                    font-size: 20px;
                }
                @media screen and (max-width: 768px) {
                    padding: 12px 0;
                    max-width: 380px;
                    font-size: 18px;
                }
                @media screen and (max-width: 425px) {
                    padding: 8px 0;
                    max-width: 320px;
                    font-size: 14px;
                    line-height: 18px;
                }
                @media screen and (max-width: 320px) {
                    font-size: 12px;
                    line-height: 16px;
                }

                & svg {
                    margin-right: 26px;
                    width: 54px;
                    height: 54px;
                    @media screen and (max-width: 425px) {
                        margin-right: 20px;
                        width: 30px;
                        height: 30px;
                    }
                    @media screen and (max-width: 320px) {
                        margin-right: 10px;
                    }
                }
            }

            &-dashboard-img {
                width: 1000px;
                height: 540px;
                background: url("~@/assets/images/dashboards_img.png");
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                @media screen and (max-width: 1600px) {
                    width: 830px;
                    height: 380px;
                }
                @media screen and (max-width: 1200px) {
                    width: 700px;
                    height: 300px;
                }
                @media screen and (max-width: 768px) {
                    max-width: 540px;
                    width: 100%;
                }
                @media screen and (max-width: 425px) {
                    max-width: 300px;
                    height: 180px;
                }
            }
        }

        ////////////
        &_lead-generation {
            width: 100%;
            padding: 30px 0 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            @media screen and (max-width: 1200px) {
                padding: 25px 0 20px;
            }
            @media screen and (max-width: 768px) {
                padding: 15px 0 10px;
            }

            &-title {
                font-weight: bold;
                font-size: 36px;
                color: $main_blue;
                @media screen and (max-width: 1440px) {
                    font-size: 32px;
                }
                @media screen and (max-width: 768px) {
                    font-size: 28px;
                }
                @media screen and (max-width: 425px) {
                    font-size: 20px;
                }
            }

            &-subtitle {
                color: $grey;
                font-size: 18px;
                font-weight: 500;
                @media screen and (max-width: 1440px) {
                    font-size: 16px;
                }
                @media screen and (max-width: 768px) {
                    font-size: 14px;
                }
                @media screen and (max-width: 425px) {
                    font-size: 12px;
                }
            }

            &-container {
                width: 100%;
                height: 320px;
                margin-top: 10px;
                background: url("~@/assets/images/line_path.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                @media screen and (max-width: 1440px) {
                    height: 280px;
                }
                @media screen and (max-width: 768px) {
                    height: unset;
                    & div {
                        flex-direction: column;
                    }
                }
            }

            &-item {
                width: 520px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 0px 0px 6px #00000029;
                border-radius: 10px;
                margin: 20px;
                padding: 30px 50px;
                color: $black;
                font-size: 18px;
                font-weight: 400;
                line-height: 25px;
                display: flex;
                align-items: center;
                @media screen and (max-width: 1440px) {
                    font-size: 16px;
                    line-height: 22px;
                    width: 450px;
                    height: 80px;
                    padding: 25px 30px;
                }
                @media screen and (max-width: 1024px) {
                    width: 300px;
                    height: 90px;
                    padding: 10px 15px;
                    margin: 15px;
                }
                @media screen and (max-width: 768px) {
                    width: 380px;
                }
                @media screen and (max-width: 425px) {
                    width: 300px;
                    margin: 10px;
                    font-size: 12px;
                    padding: 10px;
                    height: 65px;
                }
            }

            &-btn-container {
                display: flex;
                justify-content: center;
                padding: 30px 0;
                width: 100%;
                @media screen and (max-width: 768px) {
                    padding: 15px 20px;
                }
            }

            ::v-deep &-btn {

                width: 246px;
                height: 58px;
                background: $main_blue;
                color: $white;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 500;
                font-size: 16px;

                &:hover {
                    background: $main_blue;
                }

                &:focus {
                    background: $main_blue;
                    box-shadow: none;
                }

                @media screen and (max-width: 768px) {
                    height: 45px;
                    font-size: 15px;
                }
                @media screen and (max-width: 425px) {
                    width: 250px;
                    font-size: 12px;
                    height: 40px;
                }
            }
        }

        ///////////
        &_info {
            &-head {
                width: 100%;
                height: 60px;
            }

            &-container {
                width: 100%;
                background: url("~@/assets/images/info_background.png");
                background-position: center;
                background-repeat: no-repeat;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                padding: 70px 0;
                @media screen and (max-width: 1024px) {
                    padding: 50px 0;
                }
                @media screen and (max-width: 768px) {
                    padding: 50px 0;
                    min-height: 900px;
                    height: 100%;
                    background-size: cover;
                }
                @media screen and (max-width: 425px) {
                    min-height: 930px;
                }
            }

            &-title {
                color: $white;
                font-size: 25px;
                font-weight: bold;
                margin-bottom: 1rem;
                @media screen and (max-width: 768px) {
                    font-size: 22px;
                    padding-top: 20px;
                }
                @media screen and (max-width: 425px) {
                    font-size: 20px;
                    text-align: center;
                    max-width: 380px;
                }
            }

            &-subtitle {
                color: $white;
                font-size: 16px;
                font-weight: 400;
                @media screen and (max-width: 768px) {
                    font-size: 14px;
                }
                @media screen and (max-width: 425px) {
                    font-size: 12px;
                    text-align: center;
                    max-width: 360px;
                }
            }

            &-box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background: $white;
                box-shadow: 0 0 6px #00000029;
                border-radius: 10px;
                padding: 30px 25px 20px;
                @media screen and (max-width: 1024px) {
                    width: 100%;
                    padding: 30px 0 20px;
                }
                @media screen and (max-width: 768px) {
                    max-width: 500px;
                }

                &-title {
                    font-weight: 500;
                    font-size: 18px;
                    color: $main_blue;
                    @media screen and (max-width: 768px) {
                        font-size: 16px;
                    }
                }

                &-checkboxes {
                    display: flex;
                    padding: 25px 0;
                    @media screen and (max-width: 768px) {
                        padding: 18px 0;
                    }

                    ::v-deep .custom-control-inline {
                        margin-right: 30px !important;
                        @media screen and (max-width: 768px) {
                            margin-right: 20px !important;
                        }
                        @media screen and (max-width: 320px) {
                            margin-right: 10px !important;
                        }
                    }
                }

                &_row-item {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    @media screen and (max-width: 1024px) {
                        justify-content: center;
                    }
                    @media screen and (max-width: 768px) {
                        flex-direction: column;
                        align-items: center;
                    }
                }

                &-input {
                    padding: 15px 20px;
                    @media screen and (max-width: 768px) {
                        padding: 5px 20px;
                    }

                    & input {
                        width: 380px;
                        background: $light_grey;
                        border-radius: 3px;
                        border: none;
                        @media screen and (max-width: 1024px) {
                            width: 320px;
                        }
                        @media screen and (max-width: 425px) {
                            width: 300px;
                        }
                        @media screen and (max-width: 320px) {
                            width: 280px;
                        }
                    }

                    input[type=number] {
                        -moz-appearance: textfield !important;
                    }

                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none !important;
                        margin: 0 !important;
                    }
                }
            }

            &-btn {
                background: $main_blue;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 10px;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 246px;
                height: 58px;
                color: $white;
                font-size: 16px;
                font-weight: 500;
                @media screen and (max-width: 768px) {
                    font-size: 15px;
                    width: 220px;
                    height: 45px;
                }

                &:hover {
                    background: $main_blue;
                }

                &:focus {
                    background: $main_blue;
                    box-shadow: none;
                }

                &-container {
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    padding: 15px 0;
                }
            }

        }
    }

    .video {
        &_modal_content {
            position: relative;
            width: 490px;
            @media screen and (max-width: 425px) {
                width: 360px;
            }

            & .x_btn {
                position: absolute;
                top: -10px;
                right: 25px;
                width: 25px;
                height: 25px;
                cursor: pointer;
                color: $main_blue;
                z-index: 5;
            }

            & .video_container {
                width: 440px;
                height: 330px;
                padding: 10px 0 10px 30px;
                @media screen and (max-width: 425px) {
                    width: 340px;
                    padding: 0;
                }

                & video {
                    z-index: 2;
                    @media screen and (max-width: 425px) {
                        width: 330px;
                        height: 280px;
                    }
                }

            }

        }
    }

    ::v-deep .modal-content,
    ::v-deep .modal-dialog {
        width: 500px !important;
        height: 360px;
        border: none;
        @media screen and (max-width: 425px) {
            width: 360px !important;
            height: 320px;
            margin: 0 auto;
        }
    }

</style>
