import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Dashboard from "./components/layoutes/Dashboard";
import Default from "./components/layoutes/Default";
import "bootstrap-vue/dist/bootstrap-vue.css"
import "bootstrap/dist/css/bootstrap.css"
import interceptorsSetup from '@/helpers/interceptors'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

Vue.use(Multiselect)
Vue.use(Vuelidate)
interceptorsSetup()
Vue.component('dashboard-layout', Dashboard);
Vue.component('default-layout', Default);
Vue.component('Multiselect', Multiselect);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.config.productionTip = false

window.alert = new Vue();
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
