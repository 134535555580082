<template>
    <div>
        <div class="footer_container">
            <div class="footer_contact">
                <div class="footer_contact-logo">
                </div>
                <a href="tel:+1-512-637-9251">
                    <div class="footer_contact-item">
                        <b-icon icon="telephone-fill"></b-icon>
                         1-512-637-9251
                    </div>
                </a>
<!--                <div class="footer_contact-item">-->
<!--                    <b-icon icon="telephone-fill"></b-icon>-->
<!--                    1.565.265.1654-->
<!--                </div>-->
                <div class="links_hidden">
                    <ul>
                        <li class="footer_follow-link first_link">FOLLOW US</li>
                    </ul>
                    <div class="footer_social">
                        <a href="https://www.facebook.com/rezileads" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32.929" height="32.929" viewBox="0 0 32.929 32.929">
                                <path class="a_fill" id="facebook_5_" data-name="facebook (5)"
                                      d="M28.105,0H4.824A4.829,4.829,0,0,0,0,4.824V28.105a4.829,4.829,0,0,0,4.824,4.824h9.712V21.288H10.676V15.5h3.859V11.577a5.8,5.8,0,0,1,5.788-5.788h5.853v5.788H20.323V15.5h5.853l-.965,5.788H20.323V32.929h7.782a4.829,4.829,0,0,0,4.824-4.824V4.824A4.829,4.829,0,0,0,28.105,0Zm0,0"
                                      fill="#fff"/>
                            </svg>
                        </a>

<!--                        <svg xmlns="http://www.w3.org/2000/svg" width="32.929" height="32.929" viewBox="0 0 32.929 32.929">-->
<!--                            <path class="a_fill" id="linkedin_2_" data-name="linkedin (2)"-->
<!--                                  d="M28.105,0H4.824A4.829,4.829,0,0,0,0,4.824V28.105a4.829,4.829,0,0,0,4.824,4.824H28.105a4.829,4.829,0,0,0,4.824-4.824V4.824A4.829,4.829,0,0,0,28.105,0ZM11.641,26.112H7.782V12.606h3.859Zm0-15.436H7.782V6.817h3.859ZM25.147,26.112H21.288V18.394a1.929,1.929,0,0,0-3.859,0v7.718H13.57V12.606h3.859v.727a8.365,8.365,0,0,1,2.894-.727,5.051,5.051,0,0,1,4.824,5.125Zm0,0"-->
<!--                                  fill="#fff"/>-->
<!--                        </svg>-->
<!--                        <svg id="Group_3" data-name="Group 3" xmlns="http://www.w3.org/2000/svg" width="32.929"-->
<!--                             height="32.929" viewBox="0 0 32.929 32.929">-->
<!--                            <path class="a_fill" id="linkedin_2_" data-name="linkedin (2)"-->
<!--                                  d="M28.105,0H4.824A4.829,4.829,0,0,0,0,4.824V28.105a4.829,4.829,0,0,0,4.824,4.824H28.105a4.829,4.829,0,0,0,4.824-4.824V4.824A4.829,4.829,0,0,0,28.105,0ZM11.641,26.112H7.782V12.606h3.859Zm0-15.436H7.782V6.817h3.859ZM25.147,26.112H21.288V18.394a1.929,1.929,0,0,0-3.859,0v7.718H13.57V12.606h3.859v.727a8.365,8.365,0,0,1,2.894-.727,5.051,5.051,0,0,1,4.824,5.125Zm0,0"-->
<!--                                  fill="#fff"/>-->
<!--                            <rect class="a_fill" id="Rectangle_84" data-name="Rectangle 84" width="26" height="26"-->
<!--                                  transform="translate(4.962 5.101)" fill="#fff"/>-->
<!--                            <path id="twitter_3_" data-name="twitter (3)"-->
<!--                                  d="M7.2,15.8a16.566,16.566,0,0,1-2.559-.2,12.008,12.008,0,0,1-3.5-1.274L0,13.7l1.238-.407c1.354-.445,2.177-.721,3.2-1.154A4.253,4.253,0,0,1,2.249,9.605L1.961,8.73l.236.036A4.3,4.3,0,0,1,1.659,8.1,4.073,4.073,0,0,1,.968,5.682l.054-.763L1.48,5.1a4.273,4.273,0,0,1-.414-1.161A4.416,4.416,0,0,1,1.536.961l.4-.731.532.64A9.8,9.8,0,0,0,8.815,4.444a3.8,3.8,0,0,1,.231-2.017A3.777,3.777,0,0,1,10.581.666,4.612,4.612,0,0,1,13.258.009a4.275,4.275,0,0,1,2.628,1.084,8.822,8.822,0,0,0,.962-.321C17.059.693,17.3.6,17.6.5l1.1-.38-.719,2.054c.047,0,.1-.007.147-.01l1.178-.053-.7.951c-.04.054-.05.07-.064.091-.056.085-.126.19-1.081,1.466a1.8,1.8,0,0,0-.336,1.172,11.085,11.085,0,0,1-.6,4.458,7.889,7.889,0,0,1-2.118,3.137A9.354,9.354,0,0,1,9.972,15.53,13.649,13.649,0,0,1,7.2,15.8Zm0,0"-->
<!--                                  transform="translate(6.949 8.493)" fill="#00457b"/>-->
<!--                        </svg>-->
                        <a href="https://www.instagram.com/rezileads/" target="_blank">
                            <svg id="instagram_4_" data-name="instagram (4)" xmlns="http://www.w3.org/2000/svg" width="32.43"
                                 height="32.43" viewBox="0 0 32.43 32.43">
                                <path class="a_fill" id="Path_267" data-name="Path 267"
                                      d="M27.679,0H4.75A4.756,4.756,0,0,0,0,4.75V27.679a4.756,4.756,0,0,0,4.75,4.75H27.679a4.756,4.756,0,0,0,4.75-4.75V4.75A4.756,4.756,0,0,0,27.679,0Zm-11.4,24.7a8.551,8.551,0,1,1,8.551-8.551A8.56,8.56,0,0,1,16.278,24.7Zm9.5-15.2a2.85,2.85,0,1,1,2.85-2.85A2.854,2.854,0,0,1,25.779,9.5Zm0,0"
                                      fill="#fff"/>
                                <path class="a_fill" id="Path_268" data-name="Path 268"
                                      d="M392.95,90a.95.95,0,1,0,.95.95A.951.951,0,0,0,392.95,90Zm0,0"
                                      transform="translate(-367.171 -84.299)" fill="#fff"/>
                                <path class="a_fill" id="Path_269" data-name="Path 269"
                                      d="M158.651,150a6.651,6.651,0,1,0,6.651,6.651A6.659,6.659,0,0,0,158.651,150Zm0,0"
                                      transform="translate(-142.372 -140.499)" fill="#fff"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
            <div class="footer_links">
                <ul>
                    <li class="first_link">PRODUCT</li>
<!--                    <router-link to="/explore">-->
<!--                        <li>EXPLORE</li>-->
<!--                    </router-link>-->

<!--                    <li>SUCCESS STORIES</li>-->
                    <router-link to="/pricing">
                        <li>PRICING</li>
                    </router-link>
<!--                    <router-link to="/buyer_or_seller">-->
<!--                        <li>BUYER OR SELLER AGREEMENT</li>-->
<!--                    </router-link>-->
<!--                    <router-link to="/buyer_and_seller">-->
<!--                        <li>BUYER AND SELLER AGREEMENT</li>-->
<!--                    </router-link>-->


                </ul>
            </div>
            <div class="footer_links">
                <ul>
                    <li class="first_link">COMPANY</li>
                    <router-link to="/aboutUs">
                        <li>ABOUT US</li>
                    </router-link>
                    <router-link to="privacy_policy">
                        <li>PRIVACY POLICY</li>
                    </router-link>
<!--                    <router-link to="/billing_policies">-->
<!--                        <li>BILLING POLICIES</li>-->
<!--                    </router-link>-->
                    <router-link to="/tos">
                        <li>TERMS OF SERVICE</li>
                    </router-link>

                </ul>
            </div>
            <div class="footer_links">
                <ul>
                    <li class="first_link">ACCOUNT</li>
                    <router-link to="/login">
                        <li>LOG IN</li>
                    </router-link>
                    <router-link to="/register">
                        <li>SIGN UP</li>
                    </router-link>
                </ul>
            </div>
            <div class="footer_links links_follow">
                <ul>
                    <li class="footer_follow-link first_link">FOLLOW US</li>
                </ul>
                <div class="footer_social">
                    <a href="https://www.facebook.com/rezileads" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32.929" height="32.929" viewBox="0 0 32.929 32.929">
                            <path class="a_fill" id="facebook_5_" data-name="facebook (5)"
                                  d="M28.105,0H4.824A4.829,4.829,0,0,0,0,4.824V28.105a4.829,4.829,0,0,0,4.824,4.824h9.712V21.288H10.676V15.5h3.859V11.577a5.8,5.8,0,0,1,5.788-5.788h5.853v5.788H20.323V15.5h5.853l-.965,5.788H20.323V32.929h7.782a4.829,4.829,0,0,0,4.824-4.824V4.824A4.829,4.829,0,0,0,28.105,0Zm0,0"
                                  fill="#fff"/>
                        </svg>
                    </a>
<!--                    <svg xmlns="http://www.w3.org/2000/svg" width="32.929" height="32.929" viewBox="0 0 32.929 32.929">-->
<!--                        <path class="a_fill" id="linkedin_2_" data-name="linkedin (2)"-->
<!--                              d="M28.105,0H4.824A4.829,4.829,0,0,0,0,4.824V28.105a4.829,4.829,0,0,0,4.824,4.824H28.105a4.829,4.829,0,0,0,4.824-4.824V4.824A4.829,4.829,0,0,0,28.105,0ZM11.641,26.112H7.782V12.606h3.859Zm0-15.436H7.782V6.817h3.859ZM25.147,26.112H21.288V18.394a1.929,1.929,0,0,0-3.859,0v7.718H13.57V12.606h3.859v.727a8.365,8.365,0,0,1,2.894-.727,5.051,5.051,0,0,1,4.824,5.125Zm0,0"-->
<!--                              fill="#fff"/>-->
<!--                    </svg>-->
<!--                    <svg id="Group_3" data-name="Group 3" xmlns="http://www.w3.org/2000/svg" width="32.929"-->
<!--                         height="32.929" viewBox="0 0 32.929 32.929">-->
<!--                        <path class="a_fill" id="linkedin_2_" data-name="linkedin (2)"-->
<!--                              d="M28.105,0H4.824A4.829,4.829,0,0,0,0,4.824V28.105a4.829,4.829,0,0,0,4.824,4.824H28.105a4.829,4.829,0,0,0,4.824-4.824V4.824A4.829,4.829,0,0,0,28.105,0ZM11.641,26.112H7.782V12.606h3.859Zm0-15.436H7.782V6.817h3.859ZM25.147,26.112H21.288V18.394a1.929,1.929,0,0,0-3.859,0v7.718H13.57V12.606h3.859v.727a8.365,8.365,0,0,1,2.894-.727,5.051,5.051,0,0,1,4.824,5.125Zm0,0"-->
<!--                              fill="#fff"/>-->
<!--                        <rect class="a_fill" id="Rectangle_84" data-name="Rectangle 84" width="26" height="26"-->
<!--                              transform="translate(4.962 5.101)" fill="#fff"/>-->
<!--                        <path id="twitter_3_" data-name="twitter (3)"-->
<!--                              d="M7.2,15.8a16.566,16.566,0,0,1-2.559-.2,12.008,12.008,0,0,1-3.5-1.274L0,13.7l1.238-.407c1.354-.445,2.177-.721,3.2-1.154A4.253,4.253,0,0,1,2.249,9.605L1.961,8.73l.236.036A4.3,4.3,0,0,1,1.659,8.1,4.073,4.073,0,0,1,.968,5.682l.054-.763L1.48,5.1a4.273,4.273,0,0,1-.414-1.161A4.416,4.416,0,0,1,1.536.961l.4-.731.532.64A9.8,9.8,0,0,0,8.815,4.444a3.8,3.8,0,0,1,.231-2.017A3.777,3.777,0,0,1,10.581.666,4.612,4.612,0,0,1,13.258.009a4.275,4.275,0,0,1,2.628,1.084,8.822,8.822,0,0,0,.962-.321C17.059.693,17.3.6,17.6.5l1.1-.38-.719,2.054c.047,0,.1-.007.147-.01l1.178-.053-.7.951c-.04.054-.05.07-.064.091-.056.085-.126.19-1.081,1.466a1.8,1.8,0,0,0-.336,1.172,11.085,11.085,0,0,1-.6,4.458,7.889,7.889,0,0,1-2.118,3.137A9.354,9.354,0,0,1,9.972,15.53,13.649,13.649,0,0,1,7.2,15.8Zm0,0"-->
<!--                              transform="translate(6.949 8.493)" fill="#00457b"/>-->
<!--                    </svg>-->
                    <a href="https://www.instagram.com/rezileads/" target="_blank">
                        <svg id="instagram_4_" data-name="instagram (4)" xmlns="http://www.w3.org/2000/svg" width="32.43"
                             height="32.43" viewBox="0 0 32.43 32.43">
                            <path class="a_fill" id="Path_267" data-name="Path 267"
                                  d="M27.679,0H4.75A4.756,4.756,0,0,0,0,4.75V27.679a4.756,4.756,0,0,0,4.75,4.75H27.679a4.756,4.756,0,0,0,4.75-4.75V4.75A4.756,4.756,0,0,0,27.679,0Zm-11.4,24.7a8.551,8.551,0,1,1,8.551-8.551A8.56,8.56,0,0,1,16.278,24.7Zm9.5-15.2a2.85,2.85,0,1,1,2.85-2.85A2.854,2.854,0,0,1,25.779,9.5Zm0,0"
                                  fill="#fff"/>
                            <path class="a_fill" id="Path_268" data-name="Path 268"
                                  d="M392.95,90a.95.95,0,1,0,.95.95A.951.951,0,0,0,392.95,90Zm0,0"
                                  transform="translate(-367.171 -84.299)" fill="#fff"/>
                            <path class="a_fill" id="Path_269" data-name="Path 269"
                                  d="M158.651,150a6.651,6.651,0,1,0,6.651,6.651A6.659,6.659,0,0,0,158.651,150Zm0,0"
                                  transform="translate(-142.372 -140.499)" fill="#fff"/>
                        </svg>
                    </a>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "Footer",
        data() {
            return {}
        }
    }
</script>

<style scoped lang="scss">
    .footer {
        &_container {
            width: 100%;
            background: $main_blue;
            padding: 40px;
            display: flex;
            justify-content: space-around;
            @media screen and (max-width: 1700px) {
                padding: 40px 60px;
            }
            @media screen and (max-width: 1200px) {
                padding: 30px 60px;
            }
            @media screen and (max-width: 1024px) {
                padding: 20px 0;
            }
            @media screen and (max-width: 768px) {
                flex-direction: column;
            }
        }

        &_contact {
            display: flex;
            flex-direction: column;
            padding: 0 30px;
            @media screen and (max-width: 768px) {
                align-items: center;
            }

            &-logo {
                width: 230px;
                height: 30px;
                background: url("~@/assets/images/logo_white.svg");
                background-position: center;
                background-repeat: no-repeat;
                margin-bottom: 30px;
                @media screen and (max-width: 1024px) {
                    width: 180px;
                    height: 25px;
                    background-size: contain;
                    margin-bottom: 5px;
                }
            }

            &-item {
                padding: 5px 0;
                color: $white;
                @media screen and (max-width: 1024px) {
                   padding: 2px 0;
                }

                & svg {
                    margin: 0 25px 0 10px;
                }
                &:hover {
                    background: rgba(204, 204, 204, 0.08);
                }
            }
            & .links_hidden {
                display: none;
                @media screen and (max-width: 1024px) {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-top: 5px;
                    & ul {
                        margin-bottom: 5px;
                        & li {
                            list-style-type: none;
                            color: #89CBF6;;
                            padding: 0;
                        }
                    }
                }
            }
        }

        &_links {
            display: flex;
            align-items: center;
            /*justify-content: center;*/
            width: 100%;
            flex-direction: column;
            padding: 10px 65px;
            border-left: 1px solid rgba(150, 167, 180, .31);
            & .first_link {
                color: #89CBF6;
            }
            @media screen and (max-width: 1700px) {
                padding: 10px 50px;
            }
            @media screen and (max-width: 1200px) {
                padding: 10px 30px;
            }
            @media screen and (max-width: 1024px){
                padding: 10px 15px;
                &.links_follow {
                    display: none;
                }
            }
            @media screen and (max-width: 768px) {
                padding: 5px 10px;
            }

            & ul {
                @media screen and (max-width: 768px) {
                    margin-bottom: 2px;
                    text-align: center;
                }
                & li {
                    list-style-type: none;
                    color: $white;
                    padding: 8px 0;
                    @media screen and (max-width: 1440px) {
                        padding: 2px 0;
                    }

                    &:hover {
                        color: #89CBF6;
                        cursor: pointer;
                    }
                }
            }
        }

        &_follow-link {
            cursor: initial !important;
            text-align: end !important;
            &:hover {
                color: $white !important;
            }
        }

        &_social {
            width: 100%;
            display: flex;
            justify-content: center;
            & svg {
                margin: 10px;
                cursor: pointer;
                @media screen and (max-width: 1200px) {
                    margin: 5px;
                }
                &:hover .a_fill {
                    fill: #71C0F5;
                }
            }
        }
    }

</style>