<template>
    <div class="navigation_main">
        <div class="navigation_logo"></div>
        <div class="navigation_user-container">
            <b-toast id="example-toast" title="Support" static no-auto-hide>
                For support, please email <span>support@rezileads.com</span>
            </b-toast>
            <div class="navigation_user-support" @click="$bvToast.show('example-toast')">
                <svg id="help-circle" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                    <circle id="Ellipse_2" data-name="Ellipse 2" cx="10" cy="10" r="10" fill="#409edc"/>
                    <path id="Path_279" data-name="Path 279" d="M9.09,9a3,3,0,0,1,5.83,1c0,2-3,3-3,3" transform="translate(-2 -2)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <line id="Line_1" data-name="Line 1" x2="0.01" transform="translate(10 15)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                </svg>
                Support
            </div>

            <div class="navigation_user-content">
                <div class="navigation_user-avatar">
<!--                    <img :src="" alt="">-->
                </div>
                <div class="navigation_user-name">
                    <div class="navigation_u-name">{{userInfo.first_name}} {{userInfo.last_name}}</div>
                    <div class="navigation_user-dropdown">
                        <b-nav-item-dropdown text="My Settings" right>
<!--                            <b-dropdown-item @click="routeToAccount()">My Account</b-dropdown-item>-->
                            <b-dropdown-item @click="logOut()">Log Out</b-dropdown-item>
                        </b-nav-item-dropdown>
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "TopNav",
        data() {
            return {

            }
        },
        // watch: {
        //     userData: {
        //         handler(val) {
        //             console.log(val, 'kkkkkk')
        //         }
        //     }
        // },
        computed: {
          ...mapGetters(['userData']),
            userInfo()  {
              return this.userData
            }
        },
        created() {

        },
        methods: {
            logOut() {
                this.$store.commit('clearUserData')
                this.$router.push('/')
            },
            routeToAccount() {
                this.$router.push('/account')
            }
        }
    }
</script>

<style scoped lang="scss">
    @font-face {
        font-family: 'segoe-print-regular';
        src: url("~@/assets/fonts/segoepr.ttf");
    }
.navigation {
    &_main {
        position: sticky;
        top: 0;
        z-index: 11;
        width: 100%;
        height: 90px;
        background: $white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 140px;
        @media screen and (max-width: 1680px) {
            padding: 0;
            margin: 0 auto;
            max-width: 1350px;
        }
        @media screen and (max-width: 1440px) {
            max-width: unset;
            padding: 0 80px;
        }
        @media screen and (max-width: 768px) {
            padding: 0 30px;
            height: 75px;
        }
        @media screen and (max-width: 425px) {
            padding: 0 15px;
        }
    }
    &_logo {
        width: 224px;
        height: 30px;
        background: url("~@/assets/images/Logo.svg");
        background-repeat: no-repeat;
        background-position: center;
        @media screen and (max-width: 768px) {
            width: 160px;
            height: 25px;
            background-size: contain;
        }
        @media screen and (max-width: 425px) {
            width: 125px;
            height: 20px;
        }

    }
    &_u-name {
        font-family: 'segoe-print-regular', sans-serif;
        color: $light_blue;
    }
    &_user {
        &-container {
            display: flex;
            position: relative;
            & .b-toast {
                position: absolute;
                top: 60px;
                left: -80px;
                width: 255px;
                & span {
                    color: $main_blue;
                    font-size: 18px;
                }
            }
        }
        &-support {
            display: flex;
            align-items: center;
            font-size: 20px;
            color: $black;
            cursor: pointer;
            @media screen and (max-width: 768px) {
                font-size: 16px;
            }
            @media screen and (max-width: 425px) {
               font-size: 14px;
            }
            & svg {
                margin-right: 10px;
            }
        }
        &-avatar {
            width: 56px;
            height: 56px;
            background: url("~@/assets/images/profile_user.svg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            @media screen and (max-width: 768px) {
                width: 42px;
                height: 42px;
            }
            @media screen and (max-width: 425px) {
                width: 36px;
                height: 36px;
            }
        }
        &-dropdown {
            height: 26px;
            ::v-deep .dropdown-menu {
                min-width: 8rem;
                padding: 0;
                /*padding: 10px 20px;*/
            }
            ::v-deep li {
                /*height: 30px;*/
                display: flex;
                a {
                    padding: 0;
                }
                .dropdown-item {
                    color: $black !important;
                    font-size: 18px;
                    margin: 5px 0;
                    padding: 0 15px;
                    &:hover {
                        color: $main_blue !important;
                    }
                }
                &::marker {
                    display: none;
                }
            }
        }
        &-name {
            margin-left: 10px;
        }
        &-content {
            margin-left: 15px;
            display: flex;
            align-items: center;
            @media screen and (max-width: 425px) {
                margin-left: 10px;
            }
        }
    }
}
</style>