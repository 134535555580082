<template>
    <div id="app">
        <component :is="layout">
            <router-view/>
        </component>
    </div>
</template>

<script>

    export default {
        components: {},
        computed: {
            layout() {
                return this.$route.meta.layout ? `${this.$route.meta.layout}-layout` : 'default-layout'
            }
        }
    }
</script>

<style lang="scss">
    @import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Ubuntu', sans-serif;
    }

    a {
        text-decoration: none !important;
        color: initial !important;
    }
    .text_danger {
        color: red;
        font-size: 13px;
    }
</style>
