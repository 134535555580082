import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '@/store'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/login',
        name: 'Login',
        meta: {auth: false},
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
    },
    {
        path: '/register',
        name: 'Register',
        meta: {auth: false},
        component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue')
    },
    {
        path: '/forgot_password',
        name: 'ForgotPassword',
        meta: {auth: false},
        component: () => import(/* webpackChunkName: "forgotPassword" */ '../views/ForgotPassword')
    },
    {
        path: '/privacy_policy',
        name: 'PrivacyPolicy',
        meta: {auth: false},
        component: () => import(/* webpackChunkName: "privacyPolicy" */ '../views/PrivacyPolicy')
    },
    {
        path: '/billing_policies',
        name: 'BillingPolicies',
        meta: {auth: false},
        component: () => import(/* webpackChunkName: "billingPolicies" */ '../views/BillingPolicies')
    },
    {
        path: '/tos',
        name: 'Tos',
        meta: {auth: false},
        component: () => import(/* webpackChunkName: "tos" */ '../views/Tos')
    },
    {
        path: '/buyer_or_seller',
        name: 'BuyerOrSellerAgreement',
        meta: {auth: false},
        component: () => import(/* webpackChunkName: "BuyerOrSellerAgreement" */ '../views/BuyerOrSellerAgreement')
    },
    {
        path: '/buyer_and_seller',
        name: 'BuyerAndSellerAgreement',
        meta: {auth: false},
        component: () => import(/* webpackChunkName: "BuyerAndSellerAgreement" */ '../views/BuyerAndSellerAgreement')
    },
    {
        path: '/aboutUs',
        name: 'AboutUs',
        meta: {auth: false},
        component: () => import(/* webpackChunkName: "aboutUs" */ '../views/AboutUs')
    },
    {
        path: '/explore',
        name: 'Explore',
        meta: {auth: false},
        component: () => import(/* webpackChunkName: "explore" */ '../views/Explore')
    },
    {
        path: '/pricing',
        name: 'Pricing',
        meta: {auth: false},
        component: () => import(/* webpackChunkName: "pricing" */ '../views/Pricing')
    },
    {
        path: '/dashboardLeads',
        name: 'DashboardLeads',
        component: () => import(/* webpackChunkName: "dashboardLeads" */ '../views/DashboardLeads'),
        meta: {layout: 'dashboard', auth: true},
        beforeEnter(to, from, next) {
            let userData = store.getters['userData']
            if(userData.subscription_status == null) {
                next('/payment');
            }
            if(userData.subscription_status.stripe_status !== 'active') {
                next('/payment');
            }
            next()
        },
    },
    {
        path: '/inbox',
        name: 'Inbox',
        component: () => import(/* webpackChunkName: "inbox" */ '../views/Inbox'),
        meta: {layout: 'dashboard', auth: true},
        beforeEnter(to, from, next) {
            let userData = store.getters['userData']
            if(userData.subscription_status == null) {
                next('/payment');
            }
            if(userData.subscription_status.stripe_status !== 'active') {
                next('/payment');
            }
            next()
        },
    },
    {
        path: '/adTemplates',
        name: 'AdTemplates',
        component: () => import(/* webpackChunkName: "adTemplates" */ '../views/AdTemplates'),
        meta: {layout: 'dashboard', auth: true}
    },
    {
        path: '/funnels',
        name: 'Funnels',
        component: () => import(/* webpackChunkName: "funnels" */ '../views/Funnels'),
        meta: {layout: 'dashboard', auth: true},
        beforeEnter(to, from, next) {
            let userData = store.getters['userData']
            if(userData.subscription_status == null) {
                next('/payment');
            }
            if(userData.subscription_status.stripe_status !== 'active') {
                next('/payment');
            }
            next()
        },
    },
    {
        path: '/marketing',
        name: 'Marketing',
        component: () => import(/* webpackChunkName: "marketing" */ '../views/Marketing'),
        meta: {layout: 'dashboard', auth: true}
    },
    {
        path: '/account',
        name: 'Account',
        component: () => import(/* webpackChunkName: "account" */ '../views/Account'),
        meta: {layout: 'dashboard', auth: true}
    },
    {
        path: '/mainDashboard',
        name: 'MainDashboard',
        component: () => import(/* webpackChunkName: "mainDashboard" */ '../views/MainDashboard'),
        meta: {layout: 'dashboard', auth: true}
    },
    {
        path: '/leadStatuses',
        name: 'LeadStatuses',
        component: () => import(/* webpackChunkName: "leadStatuses" */ '../views/LeadStatuses'),
        meta: {layout: 'dashboard', auth: true},
        beforeEnter(to, from, next) {
            let userData = store.getters['userData']
            if(userData.subscription_status == null) {
                next('/payment');
            }
            if(userData.subscription_status.stripe_status !== 'active') {
                next('/payment');
            }
            next()
        },
    },
    {
        path: '/payment',
        name: 'Payment',
        component: () => import(/* webpackChunkName: "payment" */ '../views/Payment'),
        meta: {layout: 'dashboard', auth: true}
    },
    {
        path: '/paymentHistory',
        name: 'PaymentHistory',
        component: () => import(/* webpackChunkName: "paymentHistory" */ '../views/PaymentHistory'),
        meta: {layout: 'dashboard', auth: true}
    },
    {
        path: '/crmConnect',
        name: 'CrmConnect',
        component: () => import(/* webpackChunkName: "crmConnect" */ '../views/CrmConnect'),
        meta: {layout: 'dashboard', auth: true}
    },
    {
        path: '/emailTextTemplates',
        name: 'EmailTextTemplates',
        component: () => import(/* webpackChunkName: "emailTextTemplates" */ '../views/EmailTextTemplates'),
        meta: {layout: 'dashboard', auth: true}
    },
    {
        path: '/budgetPayment',
        name: 'BudgetPayment',
        component: () => import(/* webpackChunkName: "budgetPayment" */ '../views/BudgetPayment'),
        meta: {layout: 'dashboard', auth: true}
    },
    {
        path: '/buyerLinks',
        name: 'BuyerLinks',
        component: () => import(/* webpackChunkName: "buyerLinks" */ '../views/BuyerLinks'),
        meta: {layout: 'dashboard', auth: true}
    },
    {
        path: '/sellerLinks',
        name: 'SellerLinks',
        component: () => import(/* webpackChunkName: "sellerLinks" */ '../views/SellerLinks'),
        meta: {layout: 'dashboard', auth: true}
    },
    {
        path: '/emailTemplate',
        name: 'EmailTemplate',
        component: () => import(/* webpackChunkName: "emailTemplate" */ '../views/EmailTemplate'),
        meta: {layout: 'dashboard', auth: true}
    },
    {
        path: '/textTemplate',
        name: 'TextTemplate',
        component: () => import(/* webpackChunkName: "textTemplate" */ '../views/TextTemplate'),
        meta: {layout: 'dashboard', auth: true}
    },
    {
        path: '/leadsProfile/:id',
        name: 'LeadsProfile',
        component: () => import(/* webpackChunkName: "leadsProfile" */ '../views/LeadsProfile'),
        meta: {layout: 'dashboard', auth: true},
        props: true
    },
    {
        path: '/chooseTemplate',
        name: 'ChooseTemplate',
        component: () => import(/* webpackChunkName: "chooseTemplate" */ '../views/ChooseTemplate'),
        meta: {layout: 'dashboard', auth: true}
    },
    {
        path: '/templateItem/:id',
        name: 'TemplateItem',
        component: () => import(/* webpackChunkName: "templateItem" */ '../views/TemplateItem'),
        meta: {layout: 'dashboard', auth: true},
        props: true
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    // if(to.meta.auth && !store.getters['accessToken']) {
    let userData = store.getters['userData']
    if(to.meta.auth  && !store.getters['accessToken']) {
        router.push({name: 'Login'})
    // } else if ( userData.subscription_status === null) {
        // next({name: 'Payment'})
    } else {
            next()
            window.scrollTo(0, 0)
        }
})

// router.beforeEach((to, from, next) => {
//     let userData = store.getters['userData']
//   if(userData.subscription_status == null) {
//       router.push({name: 'Payment'})
//   } else {
//       next()
//   }
// })
// router.beforeEnter ((to, from, next) => {
//     let userData = store.getters['userData']
//     console.log(userData)
//     if(!to.meta.auth && userData.subscription_status == null) {
//         console.log(userData)
//     }else {
//         next()
//     }
//
// })


// router.beforeEach((to, from, next) => {
    // If this isn't an initial page load.

//
//     next()
//     window.scrollTo(0, 0)
// })

export default router
