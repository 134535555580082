import axios from 'axios';
import store from '@/store';

export default function setup() {

    // Request interceptor
    axios.interceptors.request.use(request => {

            const token = store.getters.accessToken;
            if(token) {
                request.headers.Authorization = `Bearer ${token}`;
            }
            // request.headers['Access-Control-Allow-Origin'] =  '*'
            // request.headers['Access-Control-Allow-Methods'] =  'GET, POST, PATCH, PUT, DELETE, OPTIONS'
            // request.headers['Access-Control-Allow-Headers'] =  'Origin, Content-Type, X-Auth-Token'
            // request.headers.set('Access-Control-Allow-Methods', ' GET, POST, PATCH, PUT, DELETE, OPTIONS')
            // request.headers.set('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token')
            return request
        },
        function (error) {
            return Promise.reject(error);
        })

    // Response interceptor
    axios.interceptors.response.use(
        function(response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        },
        async function(error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            const originalRequest = error.config;
            // if (error.response.status === 401 || error.response.status === 403) {
            //
            //
            //     // await store.dispatch("refreshToken");
            //     return axios(originalRequest);
            // }

            return Promise.reject(error);
        }
    );
}
